import * as React from 'react';
import { useEffect, useState } from 'react';

export interface IVideoPlayerProps {
  url?: string;
}

export function VideoPlayer(props: IVideoPlayerProps) {
  const [videoUrl, setVideoUrl] = useState<string>();

  useEffect(() => {
    setVideoUrl(undefined);
    setTimeout(() => {
      if (props.url) setVideoUrl(props.url);
    }, 300);
  }, [props.url]);
  return (
    <div>
      {!videoUrl ? (
        <></>
      ) : (
        <video controls>
          <source src={videoUrl} type="video/mp4" />
        </video>
      )}
    </div>
  );
}
