import { where } from 'firebase/firestore';
import { FirestoreCollectionApi } from '../../db/FireStoreApi';
import {
  ConnectionStatus,
  ConnectionType,
} from '../../../domain/types/Connection';
import { OrgType, Profile } from '../../../domain/types/Profile';

import * as profileService from '../profile/profileService';
import { ExploreCardData } from '../explore/ExploreCardData';
import { getExploreDataByOrgId } from '../explore/exploreService';
import { useEffect, useState } from 'react';
import useProfileStore from '../../appState/profileStore';

const conversationService = new FirestoreCollectionApi<any>('conversations');

const conversation_participantService = (conversationId) =>
  conversationService.getSubcollectionApi<ConvoParticipant>(
    conversationId,
    `participants`,
  );

// to check and remove
// const conversation_messagetService = (conversationId) =>
//   conversationService.getSubcollectionApi<ConvoMessage>(
//     conversationId,
//     `messages`,
//   );

export type ConvoMessage = {
  text: string;
  createdById: string;
  createdByName?: string;
  updatedOn?: Date;
  createdOn?: Date;
};

export type ConvoParticipant = {
  profileId: string;
  profileUid: string;
  profileName: string;
  permission?: string;
  lastVisited?: Date;
  lastMessageSent?: Date;
  createdOn?: Date;
  updatedOn?: Date;
  profilePic?: string;
  title?: string;
};

export function getConvoByKey(
  connectionKey: string,
  convoType: ConnectionType,
): Promise<any> {
  return conversationService.getDocsByQuery([
    where('connectionKey', '==', connectionKey),
    where('connectionType', '==', convoType),
  ]);
}

export function createConversation(
  initConvo: any,
  message: ConvoMessage | null,
  participant: ConvoParticipant,
  connectionKey: string,
): Promise<any> {
  return new Promise(async (resolve, reject) => {
    // TODO: Implement firestore transaction here
    // TODO: Check Connection is already exist or not...

    try {
      //TODO: Implement firestore transaction here
      const data = {
        ...initConvo,
        createdOn: new Date(),
        connectionStatus: ConnectionStatus.PENDING,
        lastMessage: message,
        connectionKey,
        updatedAt: new Date(),
        version: '1.0.0',
      };

      const res = await conversationService.addDoc(data);

      if (res.id) {
        if (message) await addMsgToConversation(res.id, message);
        await addParticipantToConversation(res.id, participant);
        resolve(res);
      } else {
        reject('Error creating conversation');
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function updateConversationById(
  convoId: string,
  data: any,
): Promise<any> {
  return conversationService.updateDocByKey(convoId, data);
}

export function addMsgToConversation(
  convoId: string,
  message: ConvoMessage,
): Promise<any> {
  const newMessage = {
    ...message,
    createdOn: new Date(),
    updatedOn: new Date(),
  };
  const messageService = conversationService.getSubcollectionApi(
    convoId,
    'messages',
  );

  return Promise.all([
    conversationService.updateDocByKey(convoId, { lastMessage: newMessage }),
    messageService.addDoc(newMessage),
  ]);
}

export function addParticipantToConversation(
  convoId: string,
  participant: ConvoParticipant,
): Promise<any> {
  const participantService =
    conversationService.getSubcollectionApi<ConvoParticipant>(
      convoId,
      `participants`,
    );
  return participantService.setDocByKey(participant.profileUid, {
    ...participant,
    createdOn: new Date(),
  });
}

export function updateParticipantToConversation(
  convoId: string,
  participant: ConvoParticipant,
): Promise<any> {
  const participantService =
    conversationService.getSubcollectionApi<ConvoParticipant>(
      convoId,
      `participants`,
    );
  return participantService.updateDocByKey(participant.profileUid, {
    ...participant,
    createdOn: new Date(),
  });
}

export function getConversationParticipants(
  convoId: string,
): Promise<ConvoParticipant[]> {
  const participantService =
    conversationService.getSubcollectionApi<ConvoParticipant>(
      convoId,
      `participants`,
    );
  return participantService.getAllDocs();
}

export function getConvoParticipantByUid(
  convoId: string,
  uid: string,
): Promise<ConvoParticipant> {
  return conversation_participantService(convoId).getDocByKey(uid);
}

export function upsertConvoParticipant(
  convoId: string,
  participant: ConvoParticipant,
): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const existingParticipant = await getConvoParticipantByUid(
        convoId,
        participant.profileUid,
      );

      if (existingParticipant) {
        await updateParticipantToConversation(convoId, participant);
      } else {
        await addParticipantToConversation(convoId, participant);
      }
      try {
        // update to lastVisited against to Particitpant PROFILE---
        await profileService.setProfileConversations(
          participant.profileId,
          convoId,
          { lastVisited: new Date() },
        );
      } catch (error) {
        console.error('Error updating profile conversations', error);
      }
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}

export function getOrgConversation(orgId: string): Promise<any[]> {
  return conversationService.getDocsByQuery([
    where(orgId, '==', true),
    where('connectionType', '==', 'ORG_ORG'),
  ]);
}

export function onConvoMessageUpdate(
  conversationId: string,
  callBack: (any) => void,
) {
  const messagesCollectionApi = conversationService.getSubcollectionApi(
    conversationId,
    'messages',
  );
  messagesCollectionApi.onCollectionUpdate(callBack);
}

export function onMyConversationsUpdate(
  profile: Profile,
  callBack: (any) => void,
) {
  //5Acej5oQoLkBNbBeYMLA
  //, where('connectionType', '==', 'ORG_ORG')

  // Filter Conversations USER_USER with My OrgID

  const filteredCallBack = (data) => {
    const orgConvos = data.filter((c) => c.connectionType === 'ORG_ORG');

    let userConvos = [];
    if (profile.uid) {
      userConvos = data.filter(
        (c) =>
          c.connectionType === 'USER_USER' &&
          c[profile.uid!]?.toString() === 'true',
      );
    }

    data = [...orgConvos, ...userConvos];
    callBack(data);
  };

  conversationService.onQueryUpdate(
    [where(profile.organisationId!, '==', true)],
    filteredCallBack,
  );
}

export function getConvoOrgs(
  orgs: { orgType: string; orgId: string }[],
): Promise<ExploreCardData[]> {
  const promises = orgs
    .filter((o) => o.orgId && o.orgType)
    .map((org) => getExploreDataByOrgId(org.orgId, org.orgType as OrgType));

  return Promise.all(promises);
}

export function getAllUsersOfOrgConnections(
  ConnectionsOrgIds: string[],
): Promise<any[]> {
  const promises = ConnectionsOrgIds.map((orgId) =>
    profileService.getProfilesByOrgId(orgId),
  );

  return Promise.all(promises);
}

export const getOtherOrgIdByKeyProps = (
  keyProps: any,

  profile: Profile,
) => {
  // this is to support old data format
  if (!profile) return '';

  try {
    const { organisationId } = profile;

    for (const key in keyProps) {
      const value = keyProps[key];
      if (key !== organisationId && value.type === 'org') {
        return key;
      }
    }
  } catch (error) {
    return 'unknown';
  }
};

export function useMyNetworkProfiles() {
  const { profile, orgConnections } = useProfileStore();

  const [networkProfiles, setnetworkProfiles] = useState<Profile[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const loadProfiles = async (orgIds: string[]) => {
    try {
      setLoading(true);
      const profiles = await getAllUsersOfOrgConnections(orgIds);

      setnetworkProfiles(profiles.flat());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profile?.organisationId && orgConnections?.length) {
      const orgIds = orgConnections
        .filter((o) => o.connectionStatus === ConnectionStatus.ACCEPTED)
        .map((org) => {
          let otherOrgId =
            org.toOrgId === profile.organisationId
              ? org.fromOrgId
              : org.toOrgId;

          if (!otherOrgId && org?.keyProps) {
            otherOrgId = getOtherOrgIdByKeyProps(org.keyProps, profile);
          }
          return otherOrgId;
        })
        .filter((o) => !!o);
      loadProfiles(orgIds);
    }
  }, [orgConnections, profile]);

  return { loading, networkProfiles };
}
