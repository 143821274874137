const ASSET_PATH = '/assets';

export const LOGIN_SIDE_IMG = ASSET_PATH + '/login-side-img.png';
export const WVP_LOGO_SMALL = ASSET_PATH + '/wealthVpLogo.png';
export const WVP_USER_LOGO = ASSET_PATH + '/user-icon.png';
export const WVP_LOGO_NEW = ASSET_PATH + '/WVPLogomark - White.png';
export const WVP_LOGO_Black = ASSET_PATH + '/WealthVPFull Logo - Black.png';
export const WVP_LOGO_WHITE = ASSET_PATH + '/logo-WVP.png';
export const PROFILE_DEFAULT_IMG = ASSET_PATH + '/user-icon.png';
export const DEFAULT_COMPANY_IMG = ASSET_PATH + '/default-img.jpeg';
export const DEFAULT_FIRM_IMG = ASSET_PATH + '/investor.png';
export const REAL_ESTATE_IMG = ASSET_PATH + '/realestate.png';
export const DEFAULT_IMG = ASSET_PATH + '/building 1.png';
export const Signup_Investor = ASSET_PATH + '/signup-investor.png';
export const Signup_Company = ASSET_PATH + '/signup-company.png';
export const Help = ASSET_PATH + '/Help.png';
export const Signup_RealEstateSponsor =
  ASSET_PATH + '/signup-realestatesponsor.png';
export const income_img = ASSET_PATH + '/income_10013252.png';
export const sign_up_img = ASSET_PATH + '/signup.png';
export const DEFAULT_AVATAR = '/default-avatar.png';
export const email_icon = ASSET_PATH + '/email+.png';
export const Profile_Avatar = ASSET_PATH + '/profile_avatar.jpg';
export const Comment = ASSET_PATH + '/comment.png';
export const User_Account = ASSET_PATH + '/user.png';
export const Hand_shake = ASSET_PATH + '/handshake.png';
export const Compass = ASSET_PATH + '/compass.png';
export const Mobile_logo = ASSET_PATH + '/mobile-logo.png';
export const Message = ASSET_PATH + '/message.png';
export const Explose = ASSET_PATH + '/explose.png';
export const A_user = ASSET_PATH + '/a-user.png';
export const WVP_card = ASSET_PATH + '/WVP_card.png';
export const Messageicon = ASSET_PATH + '/Community_white.png';
export const Exploseicon = ASSET_PATH + '/Explore_white.png';
export const A_usericon = ASSET_PATH + '/account_white.png';
export const networkicon = ASSET_PATH + '/Union_white.png';
export const IMAGE_PROXY = (url: string) =>
  `https://apoqrsgtqq.cloudimg.io/${url}`;

export const STICKERS_URL =
  'https://cdn.jsdelivr.net/gh/naptestdev/zalo-stickers/data/favourite.json';

export const FILE_ICON = (extension: string) =>
  `https://cdn.jsdelivr.net/gh/napthedev/file-icons/file/${extension}.svg`;

export const REACTIONS_UI: {
  [key: string]: {
    icon: string;
    gif: string;
  };
} = {
  Like: {
    icon: '/reactions-icon/like.svg',
    gif: '/reactions/like.gif',
  },
  Love: {
    icon: '/reactions-icon/love.svg',
    gif: '/reactions/love.gif',
  },
  Care: {
    icon: '/reactions-icon/care.svg',
    gif: '/reactions/care.gif',
  },
  Haha: {
    icon: '/reactions-icon/haha.svg',
    gif: '/reactions/haha.gif',
  },
  Wow: {
    icon: '/reactions-icon/wow.svg',
    gif: '/reactions/wow.gif',
  },
  Sad: {
    icon: '/reactions-icon/sad.svg',
    gif: '/reactions/sad.gif',
  },
  Angry: {
    icon: '/reactions-icon/angry.svg',
    gif: '/reactions/angry.gif',
  },
};

export const EMOJI_REPLACEMENT = {
  '😭': ['ToT', 'T-T', 'T_T', 'T.T', ':((', ':-(('],
  '😓': ["'-_-"],
  '😜': [';p', ';-p', ';P', ';-P'],
  '😑': ['-_-'],
  '😢': [":'(", ":'-("],
  '😞': [':(', ':-(', '=(', ')=', ':['],
  '😐': [':|', ':-|'],
  '😛': [':P', ':-P', ':p', ':-p', '=P', '=p'],
  '😁': [':D', ':-D', '=D', ':d', ':-d', '=d'],
  '😗': [':*', ':-*'],
  '😇': ['O:)', 'O:-)'],
  '😳': ['O_O', 'o_o', '0_0'],
  '😊': ['^_^', '^~^', '=)'],
  '😠': ['>:(', '>:-(', '>:o', '>:-o', '>:O', '>:-O'],
  '😎': ['8)', 'B)', '8-)', 'B-)', ':))'],
  '😚': ['-3-'],
  '😉': [';)', ';-)'],
  '😲': [':O', ':o', ':-O', ':-o'],
  '😣': ['>_<', '>.<'],
  '😘': [';*', ';-*'],
  '😕': [':/', ':-/', ':\\', ':-\\', '=/', '=\\'],
  '🙂': [':)', ':]', ':-)', '(:', '(='],
  '♥': ['<3'],
  '😂': [":')"],
  '🤑': ['$-)'],
};

export const EMOJI_REGEX = /^\p{Extended_Pictographic}$/u;

export const THEMES = [
  '#0D90F3',
  '#EB3A2A',
  '#0AD4EB',
  '#643ECB',
  '#93BF34',
  '#E84FCF',
  '#B43F3F',
  '#E6A50A',
  '#69C90C',
];

export const YES_NO_DDL_LIST = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const ORG_TYPE_DB_NODES = {
  company: 'companies',
  investor: 'investmentFirms',
  realestate: 'reSponser',
};

export const DEAL_STATE_LABELS = {
  investor: {
    draft: 'Saved',
    requested: 'Requested Data Room',
    denied: 'Denied',
    inprogress: 'Approved Access',
    funded: 'Funded',
    notintersted: 'Not Intersted',
  },
  company: {
    draft: 'Saved',
    requested: 'Requested Connection',
    denied: 'Denied',
    inprogress: 'Connected',
    funded: 'Funded',
    notintersted: 'Not Intersted',
  },
};

export const getMenuLinkText = (path: string): string => {
  if (path.indexOf('chat') !== -1) {
    return 'Messages';
  }
  if (path.indexOf('profile') !== -1) {
    return 'My Account';
  }
  if (path.indexOf('notifications') !== -1) {
    return 'Notifications';
  }

  if (path.indexOf('mynetwork') !== -1) {
    return 'My Network';
  }

  if (path.indexOf('company') !== -1) {
    return 'Company';
  }

  if (path.indexOf('investor') !== -1) {
    return 'Investor';
  }

  if (path.indexOf('/profile/edit') !== -1) {
    return 'Edit Profile';
  }
  if (path.indexOf('/company/edit') !== -1) {
    return 'Edit Company';
  }
  if (path.indexOf('/investor/edit') !== -1) {
    return 'Edit Investor';
  }
  if (path.indexOf('opportunity') !== -1) {
    return 'Opportunity';
  }
  if (path.indexOf('settings') !== -1) {
    return 'Settings';
  }

  if (path.indexOf('explore') !== -1) {
    return 'Explore';
  }

  // if (location.pathname.startsWith('/chat/')) {
  //   // Extract the dynamic part of the URL (e.g., chatId)
  //   // const chatId = location.pathname.split('/chats/')[1];
  //   // Set the title to "Messages"
  //   setMobileHeaderTitle('Messages');
  // } else if (location.pathname === '/chat') {
  //   setMobileHeaderTitle('Messages');
  // } else if (location.pathname === '/mynetwork') {
  //   setMobileHeaderTitle('My Network');
  // } else if (location.pathname === '/profile') {
  //   setMobileHeaderTitle('My Account');
  // } else if (location.pathname === '/notifications') {
  //   setMobileHeaderTitle('Notifications');
  // } else if (location.pathname === '/company') {
  //   setMobileHeaderTitle('Company');
  // } else if (location.pathname === '/investor') {
  //   setMobileHeaderTitle('Investor');
  // } else if (location.pathname === '/settings') {
  //   setMobileHeaderTitle('Settings');
  // } else if (location.pathname === '/profile/edit') {
  //   setMobileHeaderTitle('Edit Profile');
  // } else if (location.pathname === '/investor/edit') {
  //   setMobileHeaderTitle('Edit Investor');
  // } else if (location.pathname === '/company/edit') {
  //   setMobileHeaderTitle('Edit Company');
  // } else if (location.pathname.startsWith('/opportunity/')) {
  //   setMobileHeaderTitle('Opportunity');
  // } else {
  //   setMobileHeaderTitle('Explore');
  // }

  return '';
};

export const MENU_LINK_PATH = {
  '/chat': '  Messages',
};
