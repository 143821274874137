import { FirestoreCollectionApi } from '../../db/FireStoreApi';
import { Invite } from '../../../domain/types/Invite';
import { where } from 'firebase/firestore';
import useProfileStore from '../../appState/profileStore';
import { useState, useEffect } from 'react';
import { getAllUsersByOrgIds } from '../profile/profileService';
import { Profile } from '../../../domain/types/Profile';

const invitesCollectionService = new FirestoreCollectionApi<Invite>('invites');

export function createInvite(data: Invite): Promise<any> {
  return invitesCollectionService.addDoc({
    ...data,
    createdOn: new Date(),
    [data.createdById]: true,
    [data.createdByOrgId]: true,
  });
}

function updateInvite(id: string, data: Invite): Promise<any> {
  return invitesCollectionService.updateDocByKey(id, data);
}

export function acceptInvite(id: string, data: Invite): Promise<any> {
  return updateInvite(id, {
    ...data,
    status: 'accepted',
    acceptedOn: new Date(),
    [data.acceptedById]: true,
    [data.acceptedByOrgId]: true,
  });
}

export function getInviteById(id: string): Promise<Invite> {
  return invitesCollectionService.getDocByKey(id);
}

export function getInvitesCount(): Promise<number> {
  return invitesCollectionService.getDocsCount();
}

export function getInvites(): Promise<Invite[]> {
  return invitesCollectionService.getAllDocs();
}

export function deleteInvite(id: string): Promise<any> {
  return invitesCollectionService.deleteDocByKey(id);
}

export function getMyInvites(uid: string): Promise<any[]> {
  return invitesCollectionService.getDocsByQuery([
    where('createdById', '==', uid),
  ]);
}

export function getInviteByEmail(email: string): Promise<any> {
  return invitesCollectionService.getDocsByQuery([
    where('createToEmail', '==', email),
  ]);
}

export function getInvitesByOrg(orgId: string): Promise<any[]> {
  return invitesCollectionService.getDocsByQuery([where(orgId, '==', true)]);
}

export function useMyInvitesProfiles(): {
  isloaded: boolean;
  invitesProfiles: Profile[];
} {
  const { profile } = useProfileStore();

  const [invitesProfiles, setInviteProfiles] = useState<Profile[]>([]);

  const [isloaded, setIsloaded] = useState<boolean>(false);

  const loadProfiles = async (orgId: string) => {
    try {
      const invites: Invite[] = await getInvitesByOrg(orgId);
      const orgIds = invites
        .filter((i) => i.status === 'accepted')
        .map((invite) => {
          return orgId === invite.acceptedByOrgId
            ? invite.createdByOrgId
            : invite.acceptedByOrgId;
        });
      const profiles = await getAllUsersByOrgIds(orgIds);
      setInviteProfiles(profiles.flat());
    } catch (error) {
      console.error(error);
    } finally {
      setIsloaded(true);
    }
  };

  useEffect(() => {
    if (profile?.organisationId) {
      loadProfiles(profile?.organisationId);
    }
  }, [profile]);

  return { isloaded, invitesProfiles };
}
