import * as React from 'react';
import useProfileStore from '../../data/appState/profileStore';
import { useNavigate } from 'react-router-dom';
// import toast from '../common/Toast';
import { ShimmerSocialPost } from 'react-shimmer-effects';
import {
  NotificationIcon,
  PersonIcon,
  ProfileIcon,
} from '../common/utilComponents';

export interface IProfileProps {}

export default function Profile(props: IProfileProps) {
  const { profile } = useProfileStore();
  const orgId = profile?.organisationId;
  const navigateTo = useNavigate();

  const onEditProfile = () => {
    navigateTo('edit');
  };

  if (!profile) return <ShimmerSocialPost type="both" />;
  return (
    <div className="body_layout">
      <div className="fixed_title">
        <h3>Account Settings</h3>
      </div>

      <div className="container_profile_view">
        <div className="card" onClick={onEditProfile}>
          <div className="icon">
            <PersonIcon />
          </div>
          <h3>Personal Profile</h3>
          {profile.orgType === 'COMPANY' ? (
            <p>
              Fill out your personal profile so Investors can learn more about
              you.
            </p>
          ) : (
            <></>
          )}
          {profile.orgType !== 'COMPANY' ? (
            <p>
              Update your login details and information so we know how to
              contact you.
            </p>
          ) : (
            <></>
          )}
        </div>

        <div
          className="card"
          onClick={() => navigateTo(`/${profile?.orgType}/${orgId}/edit`)}
        >
          <div className="icon">
            <ProfileIcon />
          </div>
          <h3>{profile?.orgType?.toLowerCase()} Profile</h3>
          {profile.orgType !== 'COMPANY' ? (
            <p>
              Provide your investor and firm's information to allow companies to
              accurately match with you.
            </p>
          ) : (
            <></>
          )}
          {profile.orgType === 'COMPANY' ? (
            <p>
              Provide your company’s information to allow investors to
              accurately match with you.
            </p>
          ) : (
            <></>
          )}
          <p></p>
        </div>
        <div className="card">
          <div className="icon">
            <NotificationIcon />
          </div>
          <h3>Notifications</h3>
          <p>
            Choose notification preferences and how you want to be contacted.
          </p>
        </div>
      </div>

      {/* <Row>
        <Col span={24}>
          <div className="wvp-p-flex-d">
            <h3 className="Secondary-textcolor">
              Personal <span>Information</span>
            </h3>
            <div className="grid-3">
              <div className="label-group">
                <label htmlFor="">Name</label>
                <p>
                  {profile?.firstName} {profile?.lastName}{' '}
                </p>
              </div>
              <div className="label-group">
                <label htmlFor="">Display Name (Alias)</label>
                <p>{profile?.displayName}</p>
              </div>
              <div className="label-group">
                <label htmlFor="">Job Title</label>
                <p>{profile?.title}</p>
              </div>
              <div className="label-group">
                <label htmlFor="">Phone Number</label>
                <p>{profile.phoneNumber}</p>
              </div>
              <div className="label-group">
                <label htmlFor="">Email</label>
                <p>
                  <Link to={`mailto:${profile?.email?.toLowerCase()}`}>
                    {profile?.email}
                  </Link>
                </p>
              </div>
              <div className="label-group">
                {profile?.city ? (
                  <>
                    <label>City</label>
                    <p>{profile?.city}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="label-group">
                {profile?.state ? (
                  <>
                    <label>State</label>
                    <p>{profile?.state}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="label-group">
                {profile?.country ? (
                  <>
                    <label>Country</label>
                    <p>{profile?.country}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <h3 className="Secondary-textcolor">
              Social <span>Links</span>{' '}
            </h3>
            {profile?.socialLinks?.length !== 0 ? (
              <SocialIcons iconsList={profile?.socialLinks!!}></SocialIcons>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row> */}
    </div>
  );
}
