import { LockOutlined } from '@ant-design/icons';
import { Input, Button, Form } from 'antd';
import { useAuth } from '../../api/features/auth';
import { WVP_LOGO_NEW } from '../../v1/presentation/common/constants';
// old to remove
// import { toasty } from '../../shared/utils';s
// new to use
import toast from '../../v1/presentation/common/Toast';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

function useSearchParams<T extends string>(
  ...params: T[]
): Record<T, string | undefined> {
  const query = new URLSearchParams(useLocation().search);

  return params.reduce(
    (acc, param) => {
      acc[param] = query.get(param) ?? undefined;
      return acc;
    },
    {} as Record<T, string | undefined>,
  );
}

export const AuthAction: React.FC = () => {
  const { mode } = useSearchParams('mode');

  switch (mode) {
    case 'resetPassword':
      return <ResetPassword />;
    default:
      return <Page title="Unknown action" />;
  }
};

const Page: React.FC<React.PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => {
  return (
    <div className="flex-wrap">
      <div className="signup-box org-details ">
        <div className="signup-content">
          <div className="org-layout">
            <div className="header-img">
              <img src={WVP_LOGO_NEW} alt="Login" className="logo" />
            </div>

            <h1 className="form-title">{title}</h1>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const ResetPassword: React.FC = () => {
  const { confirmPasswordReset } = useAuth();
  const navigate = useNavigate();

  const { oobCode } = useSearchParams('oobCode');

  const onFinish = React.useCallback(
    async (values: { newPassword: string }) => {
      if (oobCode) {
        const msg = await confirmPasswordReset({
          oobCode,
          newPassword: values.newPassword,
        });
        // old to remove
        // toasty[msg.type](msg.title, msg.text);
        //new  to change later
        if (msg.text) {
          toast.info(msg.title, msg.text);
        }

        if (msg.type === 'success') navigate('/login');
      }
    },
    [oobCode, confirmPasswordReset, navigate],
  );
  return (
    <Page title="Reset Password">
      <Form
        name="confirm-password-reset-form"
        className="signup-form"
        onFinish={onFinish}
      >
        <Form.Item
          name="newPassword"
          rules={[
            {
              required: false,
              message: 'Please enter your new password',
            },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Enter your new password..."
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="btn-sign">
            Confirm Password Reset
          </Button>
        </Form.Item>
      </Form>
    </Page>
  );
};
