import * as React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export interface IReportChartProps {
  reportData: any[];
  chartConfig: any;
}

export function ReportChart({ reportData, chartConfig }: IReportChartProps) {
  if (!reportData || reportData.length === 0) {
    return <div> No Data </div>;
  }

  if (!chartConfig) {
    return <div> No Config </div>;
  }

  const getRandomColor = (): string => {
    const letters = '89ABCDEF'; // Avoids very dark (0-7) colors
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  const lineChartView = () => {
    return (
      <LineChart width={600} height={500} data={reportData}>
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey={chartConfig.xAxis} padding={{ left: 30, right: 30 }} />

        <YAxis />
        <Tooltip />

        <Legend />

        {chartConfig.yAxis.map((yAxis: string) => {
          const color = getRandomColor();
          return (
            <Line
              type="monotone"
              dataKey={yAxis}
              stroke={color}
              activeDot={{ r: 8 }}
            />
          );
        })}
      </LineChart>
    );
  };

  const barChartView = () => {
    return (
      <BarChart width={600} height={400} data={reportData}>
        <XAxis dataKey={chartConfig.xAxis} />
        <YAxis />
        {chartConfig.yAxis.map((yAxis: string) => {
          const color = getRandomColor();
          return (
            <Bar dataKey={yAxis} fill={color} label={{ position: 'top' }} />
          );
        })}

        <Tooltip />

        <Legend />
      </BarChart>
    );
  };

  switch (chartConfig.chartType) {
    case 'line':
      return lineChartView();
    case 'bar':
      return barChartView();
    default:
      return lineChartView();
  }
}
