import React from 'react';
import ColorCard from '../ColorCard';

interface ThumbnailProps {
  imageUrl?: string;
  title: string;
}
const Thumbnail: React.FC<ThumbnailProps> = ({ imageUrl, title }) => {
  return imageUrl ? (
    <img
      className="object-cover w-full h-full rounded-t-lg"
      src={imageUrl}
      alt=""
    />
  ) : (
    <ColorCard name={title} />
  );
};

export default Thumbnail;
