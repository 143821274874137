import { create } from 'zustand';
import {
  OrgTypeProperties,
  CompanySiteProperties,
  InvestorSiteProperties,
  RealestateSiteProperties,
} from '../services/common/siteDataService';

interface SiteState {
  rootLoaderState: boolean;
  fullSiteLoaderState: boolean;
  loaderMessage: string;
  OrgTypeProperties: OrgTypeProperties | null;
  companySiteProperties: CompanySiteProperties | null;
  investorSiteProperties: InvestorSiteProperties | null;
  realestateSiteProperties: RealestateSiteProperties | null;
  isMetaDataloaded: boolean;
  setRootLoader: (enabled: boolean) => void;
  setfullSiteLoader: (enabled: boolean) => void;
  setLoaderMessage: (message: string) => void;
  removeRootLoader: () => void;
  setSiteOrgProperties: (OrgTypeProperties: OrgTypeProperties) => void;
}

const useSiteStore = create<SiteState>()((set) => ({
  rootLoaderState: false,
  fullSiteLoaderState: false,
  companySiteProperties: null,
  investorSiteProperties: null,
  realestateSiteProperties: null,
  OrgTypeProperties: null,
  isMetaDataloaded: false,
  loaderMessage: 'Loading...',
  setLoaderMessage: (message) => {
    set((state) => {
      return { loaderMessage: message };
    });
  },
  setfullSiteLoader: (enabled) => {
    set((state) => {
      return { fullSiteLoaderState: enabled };
    });
  },

  setRootLoader: (enabled) => {
    set((state) => {
      return { rootLoaderState: enabled };
    });
  },
  removeRootLoader: () => {
    set((state) => ({ rootLoaderState: false }));
  },
  setSiteOrgProperties: (OrgTypePropertiess) => {
    set((state) => {
      state.OrgTypeProperties = OrgTypePropertiess;

      const {
        anticipated_term,
        arr_range,
        assets_under_management,
        business_model,
        company_structure,
        diversity_designation,
        funding_round,
        industry_type,
        investment_strategy,
        investment_structure,
        investment_type,
        investor_type,
        minority_designation,
        pre_funding_valuation,
        property_sector,
        return_structure,
        tax_advantage,
        investment_round,
        investment_size,
        economic_impact,
        environmental_impact,
        social_impact,
        diversity_and_inclusion,
      } = OrgTypePropertiess;

      state.companySiteProperties = {
        diversity_designation,
        funding_round,
        company_structure,
        business_model,
        arr_range,
        minority_designation,
        pre_funding_valuation,
        industry_type,
      };
      state.investorSiteProperties = {
        assets_under_management,
        investor_type,
        industry_type,
        investment_round,
        investment_size,
        economic_impact,
        environmental_impact,
        social_impact,
        diversity_and_inclusion,
      };
      state.realestateSiteProperties = {
        assets_under_management,
        property_sector,
        investment_type,
        investment_strategy,
        investment_structure,
        return_structure,
        anticipated_term,
        tax_advantage,
      };

      state.isMetaDataloaded = true;
      return state;
    });
  },
}));

export default useSiteStore;
