const ASSET_PATH = '/assets';

export const SIGN_UP_IMG = ASSET_PATH + '/signup.png';

export const WVP_LOGO_NEW = ASSET_PATH + '/WealthVP-Logo-Mark.svg';
export const QR_CODE_IMG = ASSET_PATH + '/qr-code-image.png';
export const GOOGLE_PLAY_BADGE = ASSET_PATH + '/google-play-badge.png';
export const APP_STORE_BADGE = ASSET_PATH + '/app-store-badge.png';
export const MOBILE_ONE = ASSET_PATH + '/mobile-1.png';
export const Messageicon = ASSET_PATH + '/Community_white.png';
export const Exploseicon = ASSET_PATH + '/Explore_white.png';
export const A_usericon = ASSET_PATH + '/account_white.png';
export const networkicon = ASSET_PATH + '/Union_white.png';
export const PROFILE_DEFAULT_IMG = ASSET_PATH + '/user-icon.png';
export const DEFAULT_COMPANY_IMG = ASSET_PATH + '/default-img.jpeg';
export const DEFAULT_FIRM_IMG = ASSET_PATH + '/investor.png';
export const REAL_ESTATE_IMG = ASSET_PATH + '/realesate.png';
export const DEFAULT_IMG = ASSET_PATH + '/building 1.png';
export const BLANK_IMG = ASSET_PATH + '/blank-img.png';
export const FOOTER_LOGO =
  ASSET_PATH + '/WealthVP-Full-Mark-Primary-Lockup.svg';
