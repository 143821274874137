import React from 'react';

export const Tag = ({ label, index }: { label: string; index: string }) => {
  return (
    <div
      className="inline-flex items-center justify-center px-2 py-1 bg-light-black rounded relative flex-none"
      key={index}
    >
      <div className="relative w-fit font-['Work_Sans'] font-medium text-white text-[14px] tracking-normal leading-[24px] max-w-[240px] overflow-hidden whitespace-nowrap text-ellipsis">
        {label}
      </div>
    </div>
  );
};
