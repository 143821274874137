import React from 'react';
import styled from 'styled-components';

export interface INotFoundProps {}

export default function NotFoundPage(props: INotFoundProps) {
  const Container = styled.div`
    text-align: center;
    padding: 20px;
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const ErrorCode = styled.h1`
    font-size: 6rem;
    color: #e7ff87;
    text-shadow:
      0px 0px 10px #e7ff87,
      0px 0px 15px #e7ff87;
  `;

  const Message = styled.p`
    font-size: 1.5rem;
    color: #e1decf;
    margin-bottom: 20px;
  `;

  const Button = styled.a`
    padding: 15px 30px;
    font-size: 1.2rem;
    color: #0f1217;
    background-color: #e7ff87;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 0px 10px #e7ff87;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e7ff87;
    }
  `;

  return (
    <>
      <Container>
        <ErrorCode>404</ErrorCode>
        <Message>Oops! The page you're looking for doesn't exist.</Message>
        <Button href="/">Go Back Home</Button>
      </Container>
    </>
  );
}
