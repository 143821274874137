import { useEffect } from 'react';
import useProfileStore from '../../data/appState/profileStore';
import React from 'react';
import { InvestmentFirm } from '../../domain/types/Investor';
import { getInvestmentFirmById } from '../../data/services/investor/investorService';

import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
// import SocialIcons from '../components/SocialIconsView';
import { ExpandedInvestmentFirmView } from '../components/ExpandedInvestmentFirmView';
import { BackArrow, EditIcon } from '../common/utilComponents';
// import { ExpandedInvestmentFirmView } from '../components/ExpandedInvestmentFirmView';
// import { investorSiteProperties } from '../../../v1/data/services/common/siteDataService';
// import { getlabelsByValues } from '../../data/services/explore/converters';

export interface IInvestorFirmPageProps {}

export default function InvestorFirmPage(props: IInvestorFirmPageProps) {
  const { id: investorFirmId } = useParams();
  // const { assets_under_management, investor_type } = investorSiteProperties;

  const { profile } = useProfileStore();
  const orgId = investorFirmId || profile?.organisationId;
  const navigateTo = useNavigate();

  const [investmentFirm, setInvestmentFirm] =
    React.useState<InvestmentFirm | null>(null);

  useEffect(() => {
    if (orgId)
      getInvestmentFirmById(orgId)
        .then((data) => {
          setInvestmentFirm(data);
        })
        .catch((err) => {});
  }, [orgId]);

  return (
    <div className="body_layout">
      {/* <Helmet>
      <title>{`WealthVP | ${investor?.legalName ?? investor?.name}`}</title>
      <link
        rel="canonical"
        href="https://wealthvp.com/company/{company?.id}"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="{'WealthVP | ' + investor?.legalName}"
      />
      <meta
        property="og:image"
        content="https://wealthvp.com/assets/favicon.png"
      />
      <meta
        property="og:description"
        content="{'Company profile of ' + investor?.legalName}"
      />
      <meta
        property="og:url"
        content="https://wealthvp.com/company/{company?.id}"
      />
      <meta property="og:site_name" content="WealthVP" />
    </Helmet> */}

      <div className="sub_header">
        <div className="back_icon" onClick={() => navigateTo(-1)}>
          <BackArrow />
          <h2>Back</h2>
        </div>
        {/* {profile?.role === 'Member' || !investmentFirm ? (
          <></>
        ) : ( */}
        <ul className="sub_header_actions">
          {profile?.id && investmentFirm?.createdById ? (
            profile?.id.toLocaleLowerCase() ===
            investmentFirm?.createdById.toLocaleLowerCase() ? (
              <li
                onClick={() =>
                  navigateTo(`/investor/${investmentFirm?.id}/edit`)
                }
              >
                <EditIcon /> <h2>Edit</h2>
              </li>
            ) : (
              <li>
                <Button type="primary" disabled>
                  Connect
                </Button>
              </li>
            )
          ) : null}
        </ul>
        {/* )} */}
      </div>
      {/* <Button onClick={()=>navigateTo('/company/edit')}>EDIT</Button> */}

      <div className="wvp-p-flex-d">
        <div className="left-top-position">
          <div className="left-info">
            {/* to check and modify */}
            {/* {investmentFirm ? (
              <div className="card">
                <div className="header-image">
                  <div className="img-div">
                    <img
                      alt=""
                      src={
                        investmentFirm?.logoUri
                          ? investmentFirm?.logoUri
                          : 'DEFAULT_IMG'
                      }
                    />
                  </div>

                  
                </div>

                <div className="card_left_body">
                  <h1 className="cn_title">
                    {investmentFirm?.name}
                  </h1>
                  <div className='list_group'>
                  <div className="text-group">
                    <label>Type</label>

                    <span>
                      {investmentFirm?.investorType
                        ? getlabelsByValues(
                            [investmentFirm?.investorType],
                            investor_type.options,
                          )
                        : ''}
                    </span>
                  </div>

                  <div className="text-group">
                    <label>Assets Under Management</label>

                    <span>
                      {investmentFirm?.assetsUnderManagement
                        ? getlabelsByValues(
                            [investmentFirm?.assetsUnderManagement],
                            assets_under_management.options,
                          )
                        : ''}
                    </span>
                  </div>

                  <div className="text-group">
                    <label>Syndicate Deals</label>
                    <span>
                      {investmentFirm?.interestedInSyndicateDeals
                        ? 'Interested'
                        : 'Not interested'}
                    </span>
                  </div>

                  <div className="text-group">
                    <label>Companies Funded</label>
                    <span>{investmentFirm?.companiesFunded}</span>
                  </div>

                  <div className="text-group">
                    <label>Active Investments</label>
                    <span>{investmentFirm?.activeInvestments}</span>
                  </div>

                  <div className="text-group">
                    <label>Exits</label>
                    <span>{investmentFirm?.exits}</span>
                  </div>

                  <div className="text-group">
                    <label>Venture Funds</label>
                    <span>{investmentFirm?.ventureFunds ?? ''}</span>
                  </div>

                  </div>
                

                  <div className='footer_social_icons'>
                  <h3 className="Secondary-textcolor">Social <span>Links</span>  </h3>
                  <div className="social_icons">
                    {investmentFirm &&
                    investmentFirm?.socialLinks &&
                    investmentFirm?.socialLinks?.length !== 0 ? (
                      //need to generte new comp and remove this
                      <SocialIcons
                        iconsList={investmentFirm?.socialLinks}
                      ></SocialIcons>
                    ) : (
                      <></>
                    )}
                  </div>
                  </div>
                  
                </div>
              </div>
            ) : (
              <></>
            )} */}
          </div>

          <div className="deck-slideshow">
            <div className="re-company-deck">
              {investmentFirm ? (
                <ExpandedInvestmentFirmView
                  investmentFirm={investmentFirm}
                  showSocialIcons={true}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
