import * as React from 'react';
import useProfileStore from '../../data/appState/profileStore';
import { Avatar, Col, List, Row } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ShimmerCategoryList } from 'react-shimmer-effects';
import { ChatWindow } from '../components/ChatWindow';
import { UserOutlined } from '@ant-design/icons';
import {
  ConvoMessage,
  getConvoOrgs,
  useMyNetworkProfiles,
} from '../../data/services/conversation/conversationService';
import { readableTime } from '../../data/services/explore/converters';
import { useEffect, useState } from 'react';
import { ExploreCardData } from '../../data/services/explore/ExploreCardData';
import {
  ConnectionStatus,
  ConnectionType,
} from '../../domain/types/Connection';
// import { DEFAULT_IMG } from '../common/asset_paths';
import { BiFilter, UserGroupIcon } from '../common/utilComponents';
import ColorCard from '../components/ColorCard';

export interface IChatProps {}

export function Chat(props: IChatProps) {
  const { profile, orgConnections, unReadConvoIds } = useProfileStore();
  const navigate = useNavigate();
  const { id: paramConvoId } = useParams();
  let conversationId: string | null = null;

  const [convoOrgs, setConvoOrgs] = useState<ExploreCardData[]>([]);

  const { networkProfiles } = useMyNetworkProfiles();

  const [chatListType, setChatListType] = useState('primary'); //primary, requests
  const [searchTerm, setSearchTerm] = useState(''); // New state for search term
  if (paramConvoId) {
    conversationId = paramConvoId;
  }

  let filteredConvos = [...orgConnections];
  if (chatListType === 'requests') {
    filteredConvos = filteredConvos.filter(
      (convo) =>
        convo.connectionType === ConnectionType.ORG_ORG &&
        convo.connectionStatus === ConnectionStatus.PENDING,
    );
  }

  const sortedConvos = filteredConvos?.map((convo) => {
    if (convo.lastMessage?.createdOn)
      convo.updatedOn = convo.lastMessage?.createdOn.toDate();
    return convo;
  });

  if (
    !paramConvoId &&
    !conversationId &&
    sortedConvos &&
    sortedConvos.length > 0
  ) {
    conversationId = sortedConvos[0].db_ref_id;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadConvoOrgs = React.useCallback(async () => {
    if (!orgConnections || !profile) return;
    const convosOrgs: { orgType: string; orgId: string }[] = orgConnections.map(
      (org) => {
        if (org.fromOrgId === profile.organisationId)
          return { orgType: org.toOrgType, orgId: org.toOrgId };
        else return { orgType: org.fromOrgType, orgId: org.fromOrgId };
      },
    );

    getConvoOrgs(convosOrgs).then(setConvoOrgs);
  }, [orgConnections, profile]);

  useEffect(() => {
    loadConvoOrgs();
  }, [orgConnections, profile, loadConvoOrgs]);

  const getConvoTitleByKeyProps = (
    keyProps: any,
    connectionType: ConnectionType,
  ) => {
    // this is to support old data format
    if (!profile) return '';

    try {
      const { organisationId, uid } = profile;

      if (connectionType === ConnectionType.USER_USER) {
        for (const key in keyProps) {
          const value = keyProps[key];
          if (key !== uid && value.type === 'user') {
            return value.name;
          }
        }
      }

      if (connectionType === ConnectionType.ORG_ORG) {
        for (const key in keyProps) {
          const value = keyProps[key];
          if (key !== organisationId && value.type === 'org') {
            return value.name;
          }
        }
      }
    } catch (error) {
      return 'unknown';
    }
  };

  const convoItem = (item) => {
    let convoAvatar = (
      <Avatar
        shape="circle"
        size={45}
        src={<UserOutlined style={{ color: '#44475b' }} />}
      />
    );
    let convoTitle: string =
      profile?.organisationId === item.toOrgId
        ? item.fromOrgName
        : item.toOrgName;

    const isGroupChat: boolean = item.connectionType === ConnectionType.ORG_ORG;

    if (isGroupChat) {
      const convoOrgId: string =
        profile?.organisationId === item.toOrgId
          ? item.fromOrgId
          : item.toOrgId;

      const convoOrgInfo: ExploreCardData | undefined = convoOrgs.find(
        (org) => org.id === convoOrgId,
      );
      if (convoOrgInfo) {
        if (convoOrgInfo?.imageUrl)
          convoAvatar = (
            <Avatar shape="circle" size={45} src={convoOrgInfo?.imageUrl} />
          );
        // convoAvatar = <Avatar shape="circle" size={45} src={DEFAULT_IMG} />;
        else
          convoAvatar = (
            <ColorCard name={convoOrgInfo?.title?.charAt(0) || 'O'} />
          );
        convoTitle = convoOrgInfo?.title;
      }
    } else {
      const convoProfileId =
        profile?.uid === item.fromUserId ? item.toUserId : item.fromUserId;

      const convoProfile = networkProfiles.find(
        (profile) => profile.uid === convoProfileId,
      );

      // if (convoProfile && convoProfile.photoURL) {
      //   convoAvatar = (
      //     <Avatar shape="circle" size={45} src={convoProfile.photoURL} />
      //   );
      // }
      if (convoProfile) {
        if (convoProfile.photoURL) {
          convoAvatar = (
            <Avatar shape="circle" size={45} src={convoProfile?.photoURL} />
          );
        } else {
          const displayName = convoProfile?.displayName || 'A'; // Default to 'U' if displayName is undefined
          convoAvatar = <ColorCard name={displayName?.charAt(0)} />;
        }
      }

      convoTitle =
        profile?.uid === item.fromUserId ? item.toUserName : item.fromUserName;
    }

    const lastMessage: ConvoMessage = item.lastMessage;

    const hasUnread = lastMessage && unReadConvoIds.includes(item.db_ref_id);

    let lastMessagesCreatedByName = '';

    if (!convoTitle && item?.keyProps) {
      convoTitle = getConvoTitleByKeyProps(item.keyProps, item.connectionType);
    }

    if (lastMessage) {
      lastMessagesCreatedByName =
        lastMessage?.createdById === profile?.uid
          ? 'You'
          : isGroupChat && lastMessage?.createdByName
            ? lastMessage?.createdByName
            : '';

      if (lastMessagesCreatedByName.length > 0) {
        lastMessagesCreatedByName += ': ';
      }
    }

    if (
      lastMessage?.createdById &&
      !item[lastMessage.createdById] &&
      item.connectionType === 'USER_USER'
    ) {
      lastMessage.text = '';
      lastMessagesCreatedByName = '';
    }

    const isActive = conversationId === item.db_ref_id;
    return (
      <Link to={`/chat/${item.db_ref_id}`}>
        <div
          className={
            'chat_card' +
            (isActive ? ' active' : '') +
            (hasUnread ? ' hasunread' : ' hasread')
          }
        >
          <div className="chat_body">
            <div className="convo-avatar">{convoAvatar}</div>
            <div className="user_content">
              <div className="user-title">{convoTitle}</div>
              <div className="role"> </div>

              <div className="time_status">
                {readableTime(lastMessage?.createdOn)}
              </div>

              <p className="last_mag text-ellipsis">
                {isGroupChat ? <UserGroupIcon /> : <></>}{' '}
                {lastMessagesCreatedByName} {lastMessage?.text}{' '}
              </p>
              {hasUnread ? <span className="green_dot"></span> : ''}
            </div>
          </div>
        </div>
      </Link>
    );
  };

  const selectChatListType = (type: string) => {
    setChatListType(type);
    navigate(`/chat`);
  };

  // search functionality for chat screen
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  let filteredConversations = sortedConvos.filter((convo) => {
    let convoTitle =
      profile?.organisationId === convo.toOrgId
        ? convo.fromOrgName
        : convo.toOrgName;

    const isGroupChat = convo.connectionType === ConnectionType.ORG_ORG;

    if (isGroupChat) {
      const convoOrgId =
        profile?.organisationId === convo.toOrgId
          ? convo.fromOrgId
          : convo.toOrgId;

      const convoOrgInfo = convoOrgs.find((org) => org.id === convoOrgId);
      if (convoOrgInfo) {
        convoTitle = convoOrgInfo?.title;
      }
    } else {
      const convoProfileId =
        profile?.uid === convo.fromUserId ? convo.toUserId : convo.fromUserId;

      const convoProfile = networkProfiles.find(
        (profile) => profile.uid === convoProfileId,
      );

      if (convoProfile) {
        convoTitle =
          profile?.uid === convo.fromUserId
            ? convo.toUserName
            : convo.fromUserName;
      }
    }

    if (!convoTitle && convo?.keyProps) {
      convoTitle = getConvoTitleByKeyProps(
        convo.keyProps,
        convo.connectionType,
      );
    }

    return (
      !convoTitle || convoTitle.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  if (!profile || !orgConnections)
    return <ShimmerCategoryList title items={6} categoryStyle="STYLE_SEVEN" />;

  filteredConversations = filteredConversations.sort((a, b) => {
    let auDate = a.updatedOn;
    let buDate = b.updatedOn;

    if (!auDate) {
      return 1;
    }
    if (!buDate) {
      return -1;
    }

    if (auDate.toDate) {
      auDate = auDate.toDate();
    }
    if (buDate.toDate) {
      buDate = buDate.toDate();
    }

    return buDate?.getTime() - auDate?.getTime();
  });

  return (
    <div
      className={
        paramConvoId === undefined
          ? 'mv-no-convo chat_row'
          : 'mv-convo-selected chat_row'
      }
    >
      {/* <div style={{color:"white"}}>
        {profile?.uid}
      </div> */}

      <Row gutter={[16, 0]} style={{ margin: '0px' }}>
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={10}
          xl={7}
          style={{ paddingLeft: '0px' }}
        >
          <div className="messagesContainer">
            <div className="header">
              <h2>
                Messages{' '}
                {unReadConvoIds.length > 0 ? (
                  <span className="badge">{unReadConvoIds.length}</span>
                ) : (
                  <></>
                )}
              </h2>
              <BiFilter />
            </div>
            <ul className="tabs_links">
              <li
                onClick={() => {
                  selectChatListType('primary');
                }}
                className={
                  chatListType === 'primary'
                    ? 'tab_link tab_active'
                    : 'tab_link'
                }
              >
                Primary
              </li>
              <li
                onClick={() => {
                  selectChatListType('requests');
                }}
                className={
                  chatListType === 'requests'
                    ? 'tab_link tab_active'
                    : 'tab_link'
                }
              >
                Requests
              </li>
            </ul>
            <div className="searchContainer">
              <input
                type="text"
                placeholder="Search"
                className="searchInput"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
            {filteredConversations.length > 0 ? (
              <List
                className="chat_lists slim_scrollbar"
                itemLayout="horizontal"
                // dataSource={[...sortedConvos]}
                dataSource={filteredConversations}
                renderItem={convoItem}
              />
            ) : (
              <div className="no_conversations">
                <span>No conversations found</span>
              </div>
            )}
          </div>
        </Col>
        <Col
          span={17}
          xs={24}
          sm={24}
          md={14}
          lg={14}
          xl={17}
          style={{ paddingRight: '0px' }}
        >
          {conversationId ? (
            <div>
              {/* <h4> {conversationId}</h4> */}
              <ChatWindow conversationId={conversationId} />
            </div>
          ) : (
            <div className="no_messages">
              {' '}
              <span>No Messages, yet</span>{' '}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
