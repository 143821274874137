import * as React from 'react';
import {
  getDataByTabelName,
  TableConfig,
} from '../../../data/services/analytics/analyticsService';
import { useCallback, useEffect } from 'react';
import { ReportChart } from './ReportChart';
import { ReportTable } from './ReportTable';
import { ReportKeyValue } from './ReportKeyValue';
import { Skeleton } from 'antd';

export interface IReportCardProps {
  tableConfig: TableConfig;
}

export function ReportCard({ tableConfig }: IReportCardProps) {
  const [reportData, setReportData] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const loadReportData = useCallback(async () => {
    try {
      const response = await getDataByTabelName(tableConfig.name);
      if (response) setReportData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [tableConfig.name]);

  useEffect(() => {
    setIsLoading(true);

    loadReportData();
  }, [loadReportData]);

  const getReportView = (reportView) => {
    if (!reportData) {
      return <></>;
    }
    const view = JSON.stringify(reportData);

    switch (reportView) {
      case 'table':
        return (
          <ReportTable
            reportData={reportData}
            tableConfig={tableConfig.reportViewConfig}
          />
        );
      case 'chart':
        return (
          <ReportChart
            reportData={reportData}
            chartConfig={tableConfig.reportViewConfig}
          />
        );
      case 'keyValue':
        return <ReportKeyValue reportData={reportData} />;
      default:
        return <div> {view} </div>;
    }
  };

  if (isLoading) {
    return <Skeleton active />;
  }

  return (
    <div className="report-card">{getReportView(tableConfig.reportView)}</div>
  );
}
