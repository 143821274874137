// import * as sdk from '@wealthvp/javascript-sdk';
// import { useCallback } from 'react';
import firebaseApi, { fbMessaging } from '../firebaseApi';
import { arrayRemove, arrayUnion, where } from 'firebase/firestore';
import { getToken } from 'firebase/messaging';

// export const useUpdateCurrentUserProfile = () => {
//   const updateCurrentUserProfile: sdk.ProfileService['updateCurrentUserProfile'] =
//     useCallback(
//       async (params) => {
//         const res = await profileService.updateCurrentUserProfile(params);

//         if (res.data) {
//           const profile = res.data;
//           updateLocalStorage('uid', profile.uid);
//           updateLocalStorage('uemail', profile.email);
//           updateLocalStorage('uname', profile.displayName);
//           updateLocalStorage('orgName', profile.organisationName);
//           updateLocalStorage('orgType', profile.orgType);
//           updateLocalStorage('organisationId', profile.organisationId);

//           //  dispatch(userActions.setCurrentUser(res.data));
//         }

//         if (res.errors.length) console.error(res.errors);

//         return res;
//       },

//       [],
//     );
//   return { updateCurrentUserProfile };
// };

// function updateLocalStorage(key: string, value: string | undefined): void {
//   if (typeof value === 'string') {
//     localStorage.setItem(key, value);
//   } else {
//     localStorage.removeItem(key);
//   }
// }

export async function saveFCMTokenToUser(uid, token) {
  try {
    const querySnapshot = await firebaseApi.getDocs(
      'profile',
      where('uid', '==', uid),
    );
    localStorage.setItem('fcmtoken', token);
    await querySnapshot.forEach((doc) => {
      const docRef = doc.ref;
      firebaseApi.setDoc(docRef, {
        PM_TOKENS: arrayUnion(token),
      });
    });
  } catch (error) {
    console.error(error);
  }
}

export async function UpdateUserPushMessageToken(uid: string) {
  const isQAEnv = window.location.host.indexOf('qa.web.app') > -1;

  const vapidKeyVal = isQAEnv
    ? 'BIlMU5xYqE5426SNPmk1l9ylzhZ5liIabOPCEUuwFWuyhQNmqrVjjuWPK3HqzVnJUA2516oD6ESLoSa5O7mb8cE'
    : 'BCCnZr4pL2Fatg1t9b47igvETbVmnMWVeD2I5-l-jIbPeP0tBJgNd-KWh9jVflo_95paARRiZ290eM85lURUboQ';

  //requesting permission using Notification API
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    try {
      if (fbMessaging) {
        const token = await getToken(fbMessaging, {
          vapidKey: vapidKeyVal,
        });

        await saveFCMTokenToUser(uid, token);
      }
    } catch (error) {
      console.error(error);
    }
  } else if (permission === 'denied') {
    console.error('Permission for Notifications was denied');
  }
}

export async function RemoveUserFCMToken() {
  //requesting permission using Notification API

  try {
    const uid = localStorage.getItem('uid'),
      token = localStorage.getItem('fcmtoken');

    const querySnapshot = await firebaseApi.getDocs(
      'profile',
      where('uid', '==', uid),
    );
    await querySnapshot.forEach((doc) => {
      const docRef = doc.ref;
      firebaseApi.setDoc(docRef, {
        PM_TOKENS: arrayRemove(token),
      });
    });
  } catch (error) {
    console.error(error);
  }
}
