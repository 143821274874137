import * as React from 'react';
import useProfileStore from '../../data/appState/profileStore';
import { Profile } from '../../domain/types/Profile';
import { Button } from 'antd';
// TO DO Kowsalya
import {
  ConvoParticipant,
  createConversation,
  getConvoByKey,
  // ConvoParticipant,
  // createConversation,
  // getConvoByKey,
  useMyNetworkProfiles,
} from '../../data/services/conversation/conversationService';
// commented for vmc changes- build
// import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import {
  ConnectionType,
  ConversationType,
} from '../../domain/types/Connection';
import { useMyInvitesProfiles } from '../../data/services/Invites/InviteService';

// table view
// import { useNavigate } from 'react-router-dom';
// import {
//   ConnectionType,
//   ConversationType,
// } from '../../domain/types/Connection';

export interface IMyNetworkProps {}

export function MyNetwork(props: IMyNetworkProps) {
  const { loading, networkProfiles } = useMyNetworkProfiles();
  const { isloaded: inviteProfilesLoaded, invitesProfiles } =
    useMyInvitesProfiles();

  let myNetworkProfiles: Profile[] = [];

  if (networkProfiles.length > 0) {
    myNetworkProfiles = [...myNetworkProfiles, ...networkProfiles];
  }

  if (inviteProfilesLoaded && invitesProfiles.length > 0) {
    myNetworkProfiles = [...myNetworkProfiles, ...invitesProfiles];
  }

  const { profile } = useProfileStore();

  if (loading) return <div>Loading...</div>;

  return (
    <div className="body_layout">
      <div className="explore_header">
        <div className="filter-wrap">
          <div className="switch-wrap">
            <div className="exp_title ms-hide">My Network</div>
            {/* <div className="filter_space">
      <div className="flight-types">
        <div
          className={`switch_label ${myNetwork === true? 'checked' : ''}`}
          onClick={() => setMyNetwork(true)}
        >
         
         My Network
          
        </div>

        <div
          className={`switch_label ${myNetwork === false? 'checked' : ''}`}
          onClick={() => setMyNetwork(false)}
        >
          
          My Communities
         
        </div>
      </div>
      
    </div> */}
          </div>
          <div></div>
        </div>
      </div>
      <TableView tableData={myNetworkProfiles} profile={profile} />
    </div>
  );
}

export interface ITableViewProps {
  tableData: Profile[];
  profile: Profile | null;
  // onViewProfile: (profile: Profile) => void;
}
export default function TableView(props: ITableViewProps) {
  // TO DO Kowsalya
  const { profile } = props;

  const navigateTo = useNavigate();

  const gotoProfileChat = (selProfile: Profile) => {
    if (profile === null) return;

    // if (profile.orgType === selProfile.orgType) {
    //   toast.error('Failed to Connect', 'Please try again after sometime');
    //   return;
    // }

    const connectionKey = [selProfile.uid, profile.uid].sort().join('_');
    getConvoByKey(connectionKey, ConnectionType.USER_USER).then((convos) => {
      if (convos.length > 0) {
        navigateTo(`/chat/${convos[0].db_ref_id}`);
      } else {
        const {
          displayName: createdByName,
          uid: createdByUid,
          orgType: fromOrgType,
          organisationId: fromOrgId,
          organisationName: fromOrgName,
        } = profile!;

        const {
          organisationId: toOrgId,
          orgType: toOrgType,
          organisationName: toOrgName,
        } = selProfile;

        const toUserId = selProfile.uid,
          fromUserId = createdByUid;

        const initState = {
          createdByUid,
          createdByName,
          fromOrgId,
          fromOrgType,
          fromOrgName,
          toOrgId,
          toOrgType,
          toOrgName,
          connectionType: ConnectionType.USER_USER,
          type: ConversationType.DIRECT,
          [toOrgId!]: true,
          [fromOrgId!]: true,
          [toUserId!]: true,
          [fromUserId!]: true,
          toUserId,
          fromUserId,
          fromUserName: createdByName,
          toUserName: selProfile.displayName,
        };

        const convoParticipant: ConvoParticipant = {
          profileId: profile.id!,
          profileUid: profile.uid!,
          profileName: profile.displayName!,
        };

        createConversation(
          initState,
          null,
          convoParticipant,
          connectionKey,
        ).then((data) => {
          if (data.id) navigateTo(`/chat/${data.id}`);
        });
      }
    });
  };
  // commented for vmc changes
  // const columns: ColumnsType<Profile> = [
  //   {
  //     title: 'First Name',
  //     dataIndex: 'firstName',
  //     key: 'name',
  //   //  sorter: sortBy('firstName'),
  //   },
  //   {
  //     title: 'Last Name',
  //     dataIndex: 'lastName',
  //     key: 'lastName',
  //   //  sorter: sortBy('lastName'),
  //   },
  //   {
  //     title: 'Company/Firm',
  //     dataIndex: 'organisationName',
  //     key: 'organisationName',
  //    // sorter: sortBy('organisationName'),
  //   },
  //   {
  //     title: 'Type',
  //     dataIndex: 'orgType',
  //     key: 'orgType',
  //    // sorter: sortBy('orgType'),
  //   },
  //   {
  //     title: 'Chat',
  //     dataIndex: 'id',
  //     key: 'id',
  //     render: (_: any, profile: Profile) => (
  //       <Button
  //         onClick={() => {
  //           gotoProfileChat(profile);
  //         }}
  //       >
  //         Chat
  //       </Button>
  //     ),
  //   },
  // ];

  return (
    <div className="table-container slim_scrollbar" key={'table-container'}>
      {/* <div style={{color:"white"}}>
{profile?.uid} / {profile?.organisationId}
      </div> */}
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Company/Firm</th>
            <th>Type</th>
            <th>Chat</th>
          </tr>
        </thead>
        <tbody>
          {props.tableData.map((data) => {
            return (
              <tr key={data.id}>
                <td>{data.firstName}</td>
                <td>{data.lastName}</td>
                <td>{data.organisationName}</td>
                <td>{data.orgType}</td>
                <td>
                  {' '}
                  <Button
                    className="message-btn"
                    onClick={() => gotoProfileChat(data)}
                  >
                    Message
                  </Button>
                  {/* <Button className="profile-btn">View Profile</Button> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* <Table<Profile>
        dataSource={props.tableData}
        columns={columns}
        scroll={{ x: 768 }}
        style={{ cursor: 'pointer' }}
      /> */}
    </div>
  );
}

// TO DO Kowsalya
// function sortBy<T, P extends keyof T>(key: P) {
//   return (a: T, b: T) => {
//     const aa = a[key];
//     const bb = b[key];

//     if (typeof aa === 'string' && typeof bb === 'string') {
//       return aa.localeCompare(bb);
//     } else if (typeof aa === 'number' && typeof bb === 'number') {
//       return aa - bb;
//     }
//     return 0;
//   };
// }
