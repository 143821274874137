import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';

import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailPassword } from '../../data/services/auth';
import toast from '../common/Toast';
import { ProcessState } from '../common/types';
import useSiteStore from '../../data/appState/siteStore';

type EmailLoginProps = {
  onSuccess?: () => void;
  onFail?: () => void;
};

const EmailLogin: React.FC<EmailLoginProps> = ({ onSuccess, onFail }) => {
  const navigate = useNavigate();
  const [processState, setProcessState] = useState<ProcessState>(
    ProcessState.Idle,
  );

  const { setRootLoader } = useSiteStore();

  const handleSubmit = async (values) => {
    setProcessState(ProcessState.Loading);
    setRootLoader(true);
    try {
      await signInWithEmailPassword({
        email: values.username,
        password: values.password,
      });
      setProcessState(ProcessState.Success);
      if (onSuccess) onSuccess();
    } catch (e) {
      setProcessState(ProcessState.Failed);
      toast.error('Login Failed', 'Please try again');
      if (onFail) onFail();
    } finally {
      setRootLoader(false);
    }
  };

  return (
    <div className="form_input">
      <Form onFinish={handleSubmit}>
        <Form.Item
          name="username"
          rules={[
            {
              required: false,
              type: 'email',
              message: 'Please enter valid email address',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Enter your email address..."
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: false, message: 'Please enter your password' }]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Enter your password..."
          />
        </Form.Item>

        <Form.Item>
          <Button
            className="btn_primary"
            type="primary"
            htmlType="submit"
            disabled={processState === ProcessState.Loading}
          >
            Login
          </Button>
        </Form.Item>
      </Form>

      <div
        onClick={() => navigate('/forgetPwd')}
        className="btn-link"
        style={{ fontSize: '1rem', cursor: 'pointer' }}
      >
        Forgot Password
      </div>
    </div>
  );
};

export default EmailLogin;
