import * as React from 'react';
import { Outlet } from 'react-router-dom';
export interface IPublicLayoutProps {}

export function PublicLayout(props: IPublicLayoutProps) {
  return (
    <div className="publiclayout">
      <Outlet></Outlet>
    </div>
  );
}
