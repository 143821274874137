import { useEffect, useState } from 'react';
import { getAllAccountsByOrg } from '../../../data/services/accounts/accountsService';
import { ConfigProvider, Input, Select } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';

export interface IAccountsProps {}

export function Accounts(props: IAccountsProps) {
  const { orgtype } = useParams<{ orgtype: string }>();
  const [filterValue, setFilterValue] = useState<string>(
    orgtype || 'companies',
  );
  const [accounts, setAccounts] = useState<any[]>([]);
  const [oAccounts, setOaccount] = useState<any[]>([]);

  useEffect(() => {
    if (filterValue) {
      loadUsers(filterValue);
    }
  }, [filterValue]);

  const columns: ColumnsType<any> = [
    {
      title: 'Organisation Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Organisation ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: 'Website',
      dataIndex: 'websiteUri',
      key: 'websiteUri',
    },
  ];

  const loadUsers = (filterValue: string) => {
    getAllAccountsByOrg(filterValue).then(
      (res: any) => {
        setOaccount(res);
        setAccounts(res);
      },
      (e: any) => {},
    );
  };

  const onsearch = (e: any) => {
    const currValue = e.target.value;
    if (currValue.trim()) {
      const filteredData = accounts.filter((entry) =>
        entry?.name?.toLowerCase().includes(currValue.toLowerCase()),
      );
      setAccounts(filteredData);
    } else {
      setAccounts(oAccounts);
    }
  };

  const handleChange = (value: string) => {
    setFilterValue(value);
  };

  return (
    <div>
      <div className=" accounts_view body_layout">
        <div className="header_view">
          <h1>Accounts</h1>
          <Input placeholder="Search Name" onChange={(e) => onsearch(e)} />

          <Select
            defaultValue={filterValue}
            onChange={handleChange}
            options={[
              { value: 'companies', label: 'Company' },
              { value: 'investmentFirms', label: 'Investment Firms' },
              { value: 'reSponser', label: 'Sponsor' },
            ]}
          />
        </div>

        <ConfigProvider
          theme={{
            components: {
              Table: {
                rowHoverBg: '#0f1217',
              },
            },
          }}
        >
          <Table dataSource={accounts} columns={columns} key="table" />
        </ConfigProvider>
      </div>
    </div>
  );
}
