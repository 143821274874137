/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line jsx-a11y/alt-text
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useProfileStore from '../../data/appState/profileStore';
import { useEffect } from 'react';
import { Company } from '../../domain/types/Company';
import { ShimmerSocialPost } from 'react-shimmer-effects';
import { getCompanyById } from '../../data/services/company/companyService';
import ExpandedCompanyView from '../components/ExpandedCompanyView';
import { Button } from 'antd';
import { Helmet } from 'react-helmet';
// import { companySiteProperties } from '../../data/services/common/siteDataService';
import {
  convertTimestampsToDates,
  // displayDateFormatter,
  // getlabelsByValues,
} from '../../data/services/explore/converters';
import { BackArrow, EditIcon } from '../common/utilComponents';
// import { convertNumbertoCurrency } from '../common/utils';
// import SocialIcons from '../components/SocialIconsView';

export interface ICompanyProps {}

export default function CompanyPage(props: ICompanyProps) {
  const { id: companyId } = useParams();
  const { profile } = useProfileStore();

  const navigateTo = useNavigate();

  const [company, setCompany] = React.useState<Company | null>(null);

  const orgId = companyId || profile?.organisationId;
  // const { company_structure, funding_round } = companySiteProperties ?? {};

  useEffect(() => {
    if (orgId)
      getCompanyById(orgId)
        .then((data) => {
          convertTimestampsToDates(data);
          setCompany(data);
        })
        .catch((err) => {});
  }, [orgId]);

  if (!profile || !company) return <ShimmerSocialPost type="both" />;

  return (
    <div className="body_layout">
      <Helmet>
        <title>{`WealthVP | ${company?.legalName ?? company?.name}`}</title>
        <link
          rel="canonical"
          href="https://wealthvp.com/company/{company?.id}"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="{'WealthVP | ' + company?.legalName}"
        />
        <meta
          property="og:image"
          content="https://wealthvp.com/assets/favicon.png"
        />
        <meta
          property="og:description"
          content="{'Company profile of ' + company?.legalName}"
        />
        <meta
          property="og:url"
          content="https://wealthvp.com/company/{company?.id}"
        />
        <meta property="og:site_name" content="WealthVP" />
      </Helmet>

      <div className="sub_header">
        <div className="back_icon" onClick={() => navigateTo(-1)}>
          <BackArrow />
          <h2>Back</h2>
        </div>
        {/* <h1 className="sub_header_title">{company?.name}</h1> */}

        {/* {profile?.role === 'Member' || !company ? (
          <></>
        ) : ( */}
        <ul className="sub_header_actions">
          {profile?.organisationId?.toLocaleLowerCase() ===
          company.id?.toLocaleLowerCase() ? (
            <li onClick={() => navigateTo('/company/' + company.id + '/edit')}>
              <EditIcon /> <h2>Edit</h2>
            </li>
          ) : (
            <li>
              <Button type="primary" disabled>
                Connect
              </Button>
            </li>
          )}
        </ul>
        {/* )} */}
      </div>
      {/* <Button onClick={()=>navigateTo('/company/edit')}>EDIT</Button> */}
      <div className="wvp-p-flex-d">
        {/* to remove later */}
        {/* <h1 className="cn_title">{company?.name}</h1> */}
        <div className="left-top-position">
          {/* to remove later */}
          {/* <div className="left-info">
            {company ? (
              <div className="card">
                <div className="header-image">
                  <div className="img-div">
                    <img
                      src={company?.logoUri ? company?.logoUri : 'DEFAULT_IMG'}
                    />
                  </div>
                </div>
                <div className="card_left_body">
             
                  <div className="list_group">
                  <h3 className="Secondary-textcolor soft_green ">Investment Details</h3>
                    <div className="text-group">
                      <label>Round</label>
                      <span>
                        {company?.fundingRound
                          ? getlabelsByValues(
                              [company?.fundingRound],
                              funding_round?.options,
                            )
                          : ''}
                      </span>
                    </div>
                    <div className="text-group">
                      <label>Closing on</label>
                      <span>{displayDateFormatter(company?.closingDate)}</span>
                    </div>

                   
                    <div className="text-group">
                      <label>Seeking</label>
                      <span>
                        <span>
                          {company && company?.seekingAmount
                            ? '$' +
                              String(company?.seekingAmount)?.replace(
                                /(\d)(?=(\d{3})+(?!\d))/g,
                                '$1,',
                              )
                            : ''}
                        </span>
                      </span>
                    </div>
                    <div className="text-group">
                      <label>Structure</label>
                      <span>
                        {getlabelsByValues(
                          company?.structures,
                          company_structure?.options,
                        )}
                      </span>
                    </div>

                    <div className="text-group">
                      <label>Min. Investment</label>
                      <span>
                        <span>
                          {company && company?.minimumInvestment
                            ? '$' +
                              String(company?.minimumInvestment)?.replace(
                                /(\d)(?=(\d{3})+(?!\d))/g,
                                '$1,',
                              )
                            : ''}
                        </span>
                      </span>
                    </div>
                    <div className="text-group">
                      <label>Syndicate deals</label>
                      <span>
                        {company?.allowSyndicateDeals
                          ? 'Allowed'
                          : 'Not allowed'}
                      </span>
                    </div>
                    <div className="text-group">
                      <label>Status</label>
                      <span>
                        {company?.status === true ? 'Raising' : 'Not Raising'}
                      </span>
                    </div>
                      

                       <h3 className="Secondary-textcolor soft_green ">Company Financials</h3>
                    <div className="text-group">
                      <label>Annual Revenue</label>
                      <span>
                        {convertNumbertoCurrency(company?.annualRecurringRevenue)}
                      </span>
                    </div>
                    <div className="text-group">
                      <label>Pre-Money</label>
                      <span>
                        {convertNumbertoCurrency(company?.preFundingValuation)}
                      </span>
                    </div>
                
                    <div className="text-group">
                      <label>Funding Raised to date</label>
                      <span>
                        <span>
                          {company && company?.fundingRaisedToDate
                            ? '$' +
                              String(company?.fundingRaisedToDate)?.replace(
                                /(\d)(?=(\d{3})+(?!\d))/g,
                                '$1,',
                              )
                            : ''}
                        </span>
                      </span>
                    </div>
                  </div>
                 
                
                </div>
              </div>
            ) : (
              <></>
            )}
          </div> */}

          <div className="deck-slideshow">
            <div className="re-company-deck">
              <ExpandedCompanyView company={company} />{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
