import React from 'react';
import { TagsList } from './TagList';

interface TagContainerProps {
  tagListTitle: string;
  tags?: string[] | number[] | null | string | number;
}

const TagContainer: React.FC<TagContainerProps> = ({ tagListTitle, tags }) => {
  return (
    <div className="flex flex-col items-start gap-1 self-stretch w-full relative flex-none h-[136px] max-h-fit">
      <div className="relative w-fit font-work-sans font-semibold text-gray-400 text-sm tracking-normal leading-5 whitespace-nowrap not-italic text-gray">
        {tagListTitle}
      </div>
      <TagsList value={tags} />
    </div>
  );
};

export default TagContainer;
