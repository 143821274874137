import * as React from 'react';
import { ExploreCardData } from '../../data/services/explore/ExploreCardData';
import ColorCard from './ColorCard';
// import { VideoPlayer } from '../common/VideoPlayer';
// import VideoByUrl from './VideoByUrl';

export interface IExploreCardProps {
  cardData: ExploreCardData;
  cardStatusAction?: () => JSX.Element;
  onCardSelect?: (cardData: ExploreCardData) => void;
}

export default function ExploreCard(props: IExploreCardProps) {
  const _cardDataValue = props.cardData;

  if (_cardDataValue?.detailsList?.length)
    _cardDataValue.detailsList = _cardDataValue.detailsList.filter((d) => {
      if (typeof d.value == 'number') {
        return true;
      } else if (d.value?.length && d.value?.length > 0) {
        return true;
      } else {
        return false;
      }
    });

  const cardData = _cardDataValue;

  const detailItemValue = (
    value?: null | string | number | string[] | number[],
  ) => {
    if (!value) return '';

    if (typeof value == 'object' && value.length) {
      return value.map((item: any, index) => {
        if (cardData.orgType === 'INVESTOR') {
          if (index > 2) return <></>;
          return (
            <div className="div-wrapper">
              <div className="text-wrapper-2">{item}</div>
            </div>
          );
        } else {
          return (
            <span className="sub-value" key={index}>
              {(index ? ', ' : '') + item}
            </span>
          );
        }
      });
    } else {
      return value;
    }
  };

  const plusCount = (
    value?: null | string | number | string[] | number[],
    maxCount = 3,
  ) => {
    if (!value) return '';

    if (typeof value == 'object' && value.length && value.length > maxCount) {
      const extraCount = value.length - maxCount;
      return <div className="text-wrapper-2">+{extraCount} </div>;
    } else {
      return <></>;
    }
  };

  return (
    <div
      key={cardData?.id}
      className={'main-card ' + cardData.orgType}
      id={cardData.id}
      onClick={() => {
        if (props.onCardSelect) {
          props.onCardSelect(cardData);
        }
      }}
    >
      <div className="card-header">
        <div className="new_tag">New</div>
        <div className="container-img">
          {cardData.imageUrl ? (
            <img className="company-logo" src={cardData.imageUrl} alt="" />
          ) : (
            <ColorCard name={cardData.title} />
          )}
        </div>

        {/* <div className='container_video'>
          {!cardData ? (
          <> </>
        ) : (
          <div>
            {cardData?.video ? (
              <VideoByUrl link={cardData?.url}></VideoByUrl>
            ) : (
              <></>
            )}
          </div>
        )}
          
          </div> */}
        <div className="header_content">
          <h2>{cardData?.name ?? ''}</h2>
          <p>{cardData.title}</p>
        </div>
      </div>
      <div className="card-content">
        <h2>{cardData.title}</h2>

        <div className="card-info">
          <div
            className={`${cardData.orgType === 'INVESTOR' ? 'info-summary' : 'entity-details'}`}
          >
            <div
              className={`${cardData?.detailsList?.length ? 'frame tag-sections-' + cardData?.detailsList?.length : 'frame no-tag-sections'}`}
            >
              <>
                {cardData?.detailsList
                  .filter((dlItem) => dlItem?.label && dlItem?.value)
                  .map((dlItem) => {
                    let isValueEmpty =
                      dlItem?.value === '' ||
                      dlItem?.value === null ||
                      dlItem?.value === undefined;
                    if (
                      typeof dlItem?.value === 'object' &&
                      dlItem?.value?.length === 0
                    )
                      isValueEmpty = true;

                    if (dlItem?.label && !isValueEmpty) {
                      if (cardData.orgType === 'INVESTOR') {
                        return (
                          <div className="tag_content">
                            <div className="text-wrapper">{dlItem?.label}</div>
                            <div className="frame-2">
                              {detailItemValue(dlItem?.value)}
                              {plusCount(dlItem?.value)}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div key={`id-${dlItem.label}`} className="list-info">
                            <span className="list_label">{dlItem?.label}</span>
                            <span className="list_value">
                              {detailItemValue(dlItem?.value)} {}
                            </span>
                          </div>
                        );
                      }
                    } else {
                      return null;
                    }
                  })}
              </>

              <div className="multine-ellipsis entity-description">
                {cardData?.about ? (
                  <>
                    <div className="text-wrapper">Firm Description</div>
                    <div
                      className="multine-ellipsis"
                      dangerouslySetInnerHTML={{
                        __html: cardData?.about,
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="conn_status">
          {props.cardStatusAction ? props.cardStatusAction() : <></>}
        </div>
      </div>
    </div>
  );
}
