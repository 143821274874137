import React from 'react';
import { UserInvites } from './UserInvites';

export interface IInvestorInvitesPageProps {}

export default function InvestorInvitesPage(props: IInvestorInvitesPageProps) {
  return (
    <div className="body_layout">
      <div className="user_invite">
        <h1>Invite Investors</h1>
        <UserInvites />
      </div>
    </div>
  );
}
