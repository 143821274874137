/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line jsx-a11y/alt-text
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useProfileStore from '../../data/appState/profileStore';
import { useEffect } from 'react';
import { Company } from '../../domain/types/Company';
import { ShimmerSocialPost } from 'react-shimmer-effects';
import { getCompanyById } from '../../data/services/company/companyService';
import ExpandedCompanyView from '../components/ExpandedCompanyView';
import { Helmet } from 'react-helmet';
import { convertTimestampsToDates } from '../../data/services/explore/converters';
import { BackArrow } from '../common/utilComponents';

export interface ICompanyMessageViewProps {}

export default function CompanyMessageView(props: ICompanyMessageViewProps) {
  const { id: companyId } = useParams();
  const { profile } = useProfileStore();

  const navigateTo = useNavigate();

  const [company, setCompany] = React.useState<Company | null>(null);

  const orgId = companyId || profile?.organisationId;

  useEffect(() => {
    if (orgId)
      getCompanyById(orgId)
        .then((data) => {
          convertTimestampsToDates(data);
          setCompany(data);
        })
        .catch((err) => {});
  }, [orgId]);

  if (!profile || !company) return <ShimmerSocialPost type="both" />;

  return (
    <div className="body_layout">
      <Helmet>
        <title>{`WealthVP | ${company?.legalName ?? company?.name}`}</title>
        <link
          rel="canonical"
          href="https://wealthvp.com/company/{company?.id}"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="{'WealthVP | ' + company?.legalName}"
        />
        <meta
          property="og:image"
          content="https://wealthvp.com/assets/favicon.png"
        />
        <meta
          property="og:description"
          content="{'Company profile of ' + company?.legalName}"
        />
        <meta
          property="og:url"
          content="https://wealthvp.com/company/{company?.id}"
        />
        <meta property="og:site_name" content="WealthVP" />
      </Helmet>
      <div className="sub_header">
        <div className="back_icon" onClick={() => navigateTo(-1)}>
          <BackArrow />
          <h1>Back</h1>
        </div>
      </div>

      <div className="wvp-p-flex-d">
        <div className="left-top-position">
          <div className="deck-slideshow">
            <div className="re-company-deck">
              <ExpandedCompanyView company={company} />{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
