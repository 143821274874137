import { ConfigProvider, Table } from 'antd';
import * as React from 'react';

export interface IReportTableProps {
  reportData: any[];
  tableConfig: any;
}

export function ReportTable(props: IReportTableProps) {
  const { reportData, tableConfig } = props;

  if (!reportData || reportData.length === 0) {
    return <div> No Data </div>;
  }

  if (!tableConfig) {
    return <div> No Config </div>;
  }

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#0f1217',
            },
          },
        }}
      >
        <Table
          dataSource={reportData}
          columns={tableConfig.columns}
          size="small"
          scroll={{ y: 380 }}
          pagination={false}
        />
      </ConfigProvider>
    </div>
  );
}
