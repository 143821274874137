import * as React from 'react';
import { Divider, Space, Tabs, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { InvestmentFirm } from '../../domain/types/Investor';
// need to check
// import { useState } from 'react';

// need to check
import { getlabelsByValues } from '../../data/services/explore/converters';
import { investorSiteProperties } from '../../data/services/common/siteDataService';
import { DEFAULT_FIRM_IMG } from '../common/constants';
import SocialIcons from './SocialIconsView';
// profile/tsx
import useProfileStore from '../../data/appState/profileStore';
import { Avatar } from 'antd';
import { Profile } from '../../domain/types/Profile';
import { useEffect } from 'react';
import { getProfilesByOrgId } from '../../data/services/profile/profileService';

export interface IExpandedInvestmentFirmViewProps {
  investmentFirm: InvestmentFirm;
  cardStatusAction?: () => JSX.Element;
  showSocialIcons?: boolean;
}

export function ExpandedInvestmentFirmView(
  props: IExpandedInvestmentFirmViewProps,
) {
  // from profile.tsx

  const [firmProfile, setFirmProfile] = React.useState<Profile | null>(null);

  const { profile: currentProfile } = useProfileStore();

  const { investmentFirm } = props;
  const photoURL = firmProfile?.photoURL ?? '';

  useEffect(() => {
    if (currentProfile?.orgType === 'INVESTOR') {
      setFirmProfile(currentProfile);
    } else if (investmentFirm) {
      const orgId = investmentFirm?.id || investmentFirm?.db_ref_id;
      if (orgId)
        getProfilesByOrgId(orgId).then((response) => {
          if (response && response.length > 0) {
            setFirmProfile(response[0]);
          }
        });
    }
  }, [currentProfile, investmentFirm]);

  // need to check
  // const [expanded, setExpanded] = useState(false);
  const {
    industry_type,
    social_impact,
    investment_round,
    diversity_and_inclusion,
    economic_impact,
    investment_size,
    environmental_impact,
  } = investorSiteProperties;
  const { assets_under_management, investor_type } = investorSiteProperties;
  // need to check
  // const toggleExpand = () => {
  //   setExpanded(!expanded);
  // };

  const getUrl = (url) => {
    if (url && (url?.indexOf('http') === -1 || url.indexOf('www.') === 0)) {
      return `https://${url}`;
    } else {
      return url;
    }
  };
  const firmOverView = () => {
    return (
      <div className="inv_slide_view">
        {InvestorInvestmentProfile(props)}

        <h3 className="Secondary-textcolor soft_green">Industry Focus</h3>

        <Space size={8} wrap className="tag_lists">
          {getlabelsByValues(
            investmentFirm?.industries,
            industry_type.options,
          ).map((label: string, index: number) => (
            <Tag key={index}>
              <div className="tag_text"> {label}</div>
            </Tag>
          ))}
        </Space>

        <h3 className="Secondary-textcolor soft_green ">Firm</h3>

        <div className="width_div">
          <div className="list_group">
            <div className="text-group">
              {investmentFirm?.city ? (
                <>
                  <label htmlFor="">City</label>{' '}
                  <span>{investmentFirm?.city}</span>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="text-group">
              {investmentFirm?.state ? (
                <>
                  <label htmlFor="">State</label>
                  <span> {investmentFirm?.state} </span>
                </>
              ) : (
                <></>
              )}{' '}
            </div>
            <div className="text-group">
              {investmentFirm?.country ? (
                <>
                  <label htmlFor="">Country</label>{' '}
                  <span>{investmentFirm?.country}</span>{' '}
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="text-group">
              <label htmlFor="">Website</label>

              <Link
                target="blank"
                to={getUrl(
                  investmentFirm?.websiteUri === 'https://null'
                    ? ''
                    : investmentFirm?.websiteUri,
                )}
              >
                <span>
                  {investmentFirm?.websiteUri === 'https://null'
                    ? ''
                    : investmentFirm?.websiteUri}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const items = [
    {
      label: 'Overview',
      key: '1',
      children: firmOverView(),
    },
  ];
  const aboutText = investmentFirm?.about || '';
  // need to check
  // const isLongText = aboutText.length > 400;

  return (
    <>
      <div className="side-view-comapny">
        <div className="account-profile">
          <div className="frame">
            <div className="div_content">
              <div className="frame-2">
                <div className="div-wrapper">
                  {photoURL ? (
                    <Avatar
                      shape="circle"
                      alt="Image"
                      icon={<img src={photoURL} alt="" />}
                    />
                  ) : (
                    <>
                      {' '}
                      <Avatar shape="circle">
                        {firmProfile?.firstName?.charAt(0).toUpperCase()}
                        {firmProfile?.lastName?.charAt(0).toUpperCase()}
                      </Avatar>{' '}
                    </>
                  )}
                </div>
                <div className="frame-3">
                  <div className="text-wrapper-2">
                    {' '}
                    {firmProfile?.firstName} {firmProfile?.lastName}
                  </div>
                  <div className="frame-4">
                    <p className="JS-investments">
                      <span className="span">{firmProfile?.title} </span>
                      <span className="span">at {investmentFirm?.name}</span>
                      {/* <span className="text-wrapper">  </span> */}
                      {!investmentFirm?.investorType ? (
                        <></>
                      ) : (
                        getlabelsByValues(
                          [investmentFirm?.investorType],
                          investor_type.options,
                        ).map((label: string, index: number) => (
                          <span className="span" key={`${label}-${index}`}>
                            {' '}
                            | {label}
                          </span>
                        ))
                      )}
                    </p>
                    <div className="text-wrapper-4">
                      {[
                        firmProfile?.city,
                        firmProfile?.state,
                        firmProfile?.country,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-5">
                {firmProfile?.socialLinks?.length !== 0 ? (
                  <SocialIcons
                    iconsList={firmProfile?.socialLinks!}
                  ></SocialIcons>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="main-container">
          <div className="grid_wrapper">
            {investmentFirm?.investmentRound ? (
              <div className="wrapper">
                <span className="text">Investment Round</span>
                <div className="group">
                  {getlabelsByValues(
                    investmentFirm?.investmentRound,
                    investment_round.options,
                  ).map((label: string, index: number) => (
                    <div key={index} className="box">
                      <span className="text-2"> {label}</span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
            {investmentFirm?.investmentSize ? (
              <div className="wrapper">
                <span className="text">Investment Size</span>
                <div className="group">
                  {getlabelsByValues(
                    investmentFirm?.investmentSize,
                    investment_size.options,
                  ).map((label: string, index: number) => (
                    <div key={index} className="box">
                      <span className="text-2"> {label}</span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}

            {investmentFirm?.geographicFocus ? (
              <div className="wrapper">
                <span className="text">Geographic Focus</span>
                <div className="group">
                  <div className="box">
                    <span className="text-2">
                      {investmentFirm?.geographicFocus}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="box-9">
            <span className="text">Industry Focus</span>
            <div className="group">
              {getlabelsByValues(
                investmentFirm?.industries,
                industry_type.options,
              ).map((label: string, index: number) => (
                <div key={index} className="box">
                  <span className="text-2"> {label}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="container">
            {!investmentFirm?.socialImpact &&
            !investmentFirm?.socialImpact &&
            !investmentFirm?.economicImpact &&
            !investmentFirm?.diversityandInclusion ? (
              ''
            ) : (
              <div className="impact-areas">
                <h3>Impact Areas of Interest</h3>
                <ul>
                  {investmentFirm?.socialImpact ? (
                    <li>
                      <strong>Social Impact:</strong>
                      <>
                        {getlabelsByValues(
                          investmentFirm?.socialImpact,
                          social_impact.options,
                        ).map((label: string, index: number) => (
                          <div key={index} className="box">
                            <span className="text-2"> {label}</span>
                          </div>
                        ))}
                      </>
                    </li>
                  ) : (
                    ''
                  )}
                  {investmentFirm?.environmentImpact ? (
                    <li>
                      <strong>Environmental Impact:</strong>{' '}
                      <>
                        {getlabelsByValues(
                          investmentFirm?.environmentImpact,
                          environmental_impact.options,
                        ).map((label: string, index: number) => (
                          <div key={index} className="box">
                            <span className="text-2"> {label}</span>
                          </div>
                        ))}
                      </>
                    </li>
                  ) : (
                    ''
                  )}

                  {investmentFirm?.economicImpact ? (
                    <li>
                      <strong>Economic Impact:</strong>{' '}
                      <>
                        {getlabelsByValues(
                          investmentFirm?.economicImpact,
                          economic_impact.options,
                        ).map((label: string, index: number) => (
                          <div key={index} className="box">
                            <span className="text-2"> {label}</span>
                          </div>
                        ))}
                      </>
                    </li>
                  ) : (
                    ''
                  )}

                  {investmentFirm?.diversityandInclusion ? (
                    <li>
                      <strong>Diversity and Inclusion:</strong>{' '}
                      <>
                        {getlabelsByValues(
                          investmentFirm?.diversityandInclusion,
                          diversity_and_inclusion.options,
                        ).map((label: string, index: number) => (
                          <div key={index} className="box">
                            <span className="text-2"> {label}</span>
                          </div>
                        ))}
                      </>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            )}
            {!investmentFirm?.exits && !investmentFirm?.notableInvestments ? (
              ''
            ) : (
              <div className="investment-successes">
                <h3>Investment Successes</h3>
                <ul>
                  {investmentFirm?.exits ? (
                    <li>
                      <strong>Number of Exits:</strong>{' '}
                      <span>{investmentFirm?.exits}</span>
                    </li>
                  ) : (
                    ''
                  )}
                  {investmentFirm?.notableInvestments ? (
                    <li>
                      <strong>Notable Investments:</strong>{' '}
                      <span>{investmentFirm?.notableInvestments}</span>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            )}
          </div>

          <Divider style={{ margin: '0px 0px' }} />

          <div style={{ width: '100%' }}>
            <h3 className="soft_green_title"> Firm Information</h3>

            <div className="firm-info">
              <div className="logo-section">
                <img
                  src={
                    investmentFirm?.logoUri
                      ? investmentFirm?.logoUri
                      : DEFAULT_FIRM_IMG
                  }
                  alt="investmentFirm logo"
                />
              </div>
              <div className="details-section">
                <div className="firm_title">
                  <h3>{investmentFirm?.name ?? ''}</h3>
                  <div className="website-section">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={getUrl(
                        investmentFirm?.websiteUri === 'https://null'
                          ? ''
                          : investmentFirm?.websiteUri,
                      )}
                    >
                      {investmentFirm?.websiteUri === 'https://null'
                        ? ''
                        : 'Visit Website'}
                    </a>
                  </div>
                </div>

                <ul>
                  <li>
                    <div className="text-group">
                      <strong>Assets Under Management:</strong>{' '}
                      {!investmentFirm?.assetsUnderManagement ? (
                        <></>
                      ) : (
                        getlabelsByValues(
                          [investmentFirm?.assetsUnderManagement],
                          assets_under_management.options,
                        ).map((label: string, index: number) => (
                          <span key={`${label}-${index}`}>{label}</span>
                        ))
                      )}
                    </div>
                  </li>
                </ul>
                {/* <p className="description">
                JS Investments is a single family-owned firm that specializes in
                small SaaS startups. JS Investments is a single family-owned
                firm that specializes in small SaaS startups. JS Investments is
                a single family-owned firm that specializes in small SaaS
                startups. JS Investments is a single family-owned firm that
                specializes in small SaaS startups.
              </p> */}
                <div className="about_info description">
                  {/* Display truncated or full content based on 'expanded' state */}
                  {aboutText && (
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: aboutText,
                      }}
                    />
                  )}

                  {/* Show More/Less link based on content length */}
                  {/* {isLongText && (
                  <a href="#!" onClick={toggleExpand}>
                    {expanded ? 'Show Less' : 'Show More'}
                  </a>
                )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Tabs defaultActiveKey="1" items={items} style={{ display: 'none' }} />
      </div>

      <div className="message-box">
        <h2>Send a message?</h2>
        <p>Once accepted, they will join your network.</p>
        <div className="org-status-action">
          {props.cardStatusAction ? props.cardStatusAction() : <></>}
        </div>
      </div>
    </>
  );
}

export function InvestorInvestmentProfile(
  props: IExpandedInvestmentFirmViewProps,
) {
  const { investmentFirm } = props;

  return (
    <>
      <div className="profile_hide">
        <div className="cvr_flex_wrapping">
          <div className="logo-company">
            <img
              src={
                investmentFirm?.logoUri
                  ? investmentFirm?.logoUri
                  : DEFAULT_FIRM_IMG
              }
              alt="investmentFirm logo"
            />
          </div>

          <div className="colounm_two">
            <h3 className="Secondary-textcolor soft_green">
              Investment Profile
            </h3>
            <div className="list_group"></div>

            <h3 className="Secondary-textcolor soft_green">
              Investment Activities
            </h3>

            <div className="list_group">
              <div className="text-group">
                <label>Syndicate Deals</label>
                <span>{investmentFirm?.interestedInSyndicateDeals}</span>
              </div>

              <div className="text-group">
                <label>Companies Funded</label>
                <span>{investmentFirm?.companiesFunded}</span>
              </div>

              <div className="text-group">
                <label>Active Investments</label>
                <span>{investmentFirm?.activeInvestments}</span>
              </div>

              <div className="text-group">
                <label>Exits</label>
                <span>{investmentFirm?.exits}</span>
              </div>

              <div className="text-group">
                <label>Venture Funds</label>
                <span>{investmentFirm?.ventureFunds ?? ''}</span>
              </div>
            </div>
            <div className="footer_social_icons">
              <h3 className="Secondary-textcolor">
                Social <span>Links</span>{' '}
              </h3>
              <div className="social_icons">
                {investmentFirm &&
                investmentFirm?.socialLinks &&
                investmentFirm?.socialLinks?.length !== 0 ? (
                  //need to generte new comp and remove this
                  <SocialIcons
                    iconsList={investmentFirm?.socialLinks}
                  ></SocialIcons>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="org-status-action">
              {props.cardStatusAction ? props.cardStatusAction() : <></>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
