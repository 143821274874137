/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useProfileStore from '../../data/appState/profileStore';
import { ShimmerSocialPost } from 'react-shimmer-effects';
import {
  Avatar,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tabs,
} from 'antd';
import {
  Company,
  CompanyFile,
  CompanyFileCategories,
  TeamMember,
} from '../../domain/types/Company';
import { useEffect, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';

import dayjs from 'dayjs';

import FileUploader from '../common/FileUploader';
import { DEFAULT_FIRM_IMG, YES_NO_DDL_LIST } from '../common/constants';
import { FileLoader } from '../common/FileLoader';
import { formatBytes } from '../../data/services/explore/converters';
import { companySiteProperties } from '../../data/services/common/siteDataService';
import {
  getCompanyById,
  updateCompany,
} from '../../data/services/company/companyService';
import { SocialLinksEditor } from '../components/social-links-form-item';
import toast from '../common/Toast';
import {
  BackArrow,
  DeleteIcons,
  UploadCloudIcon,
} from '../common/utilComponents';
import { TeamMemberForm } from '../components/TeamMemberForm';
import { TeamMembersList } from '../components/TeamMembersList';
import { Profile } from '@wealthvp/javascript-sdk';
import { PdfToImagesUploader } from '../components/PdfToImagesUploader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import VideoByUrl from '../components/VideoByUrl';

export interface ICompanyEditProps {}

export default function CompanyEdit(props: ICompanyEditProps) {
  const {
    diversity_designation,
    funding_round,
    company_structure,
    business_model,
    arr_range,
    minority_designation,
    pre_funding_valuation,
    industry_type,
  } = companySiteProperties ?? {};

  const { id: companyId } = useParams();
  const { profile } = useProfileStore();
  const navigateTo = useNavigate();

  const [company, setCompany] = React.useState<Company | null>(null);
  const [pictureUrl, setPictureUrl] = useState<any>(company?.logoUri ?? '');
  const [socialLinks, setSocialLinks] = useState<string[]>(
    company?.socialLinks ?? [],
  );
  // const [statusValue, setStatusValue] = useState<boolean>();
  const [industries, setIndustries] = useState<string[]>(
    company?.industries ?? [],
  );
  const [companyFiles, setCompanyFiles] = useState<CompanyFile[]>(
    company?.companyFiles ?? [],
  );
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>(
    company?.teamMembers ?? [],
  );
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [pitchDeckImages, setPitchDeckImages] = useState<string[]>([]);

  useEffect(() => {
    if (companyId)
      getCompanyById(companyId)
        .then((data: Company) => {
          const dateVal: any = data.closingDate;
          if (dateVal?.toDate) {
            data.closingDate = dateVal.toDate();
          }
          if (data.websiteUri === 'https://null') {
            data.websiteUri = '';
          }
          setCompany(data);
          if (data.teamMembers?.length) {
            setTeamMembers(data.teamMembers);
          }
          if (data.socialLinks?.length) {
            setSocialLinks(data.socialLinks);
          }
          if (data.companyFiles?.length) {
            setCompanyFiles(data.companyFiles);
          }
          if (data.pitchDeckImageFiles?.length) {
            setPitchDeckImages(data.pitchDeckImageFiles);
          }

          if (data.logoUri?.length) {
            setPictureUrl(data.logoUri);
          }
        })
        .catch((err) => {});
  }, [companyId]);

  const [form] = Form.useForm<Company>();

  if (!profile || !company) return <ShimmerSocialPost type="both" />;

  const onFinish = async (values: Company) => {
    if (companyFiles) {
      const pDeck = companyFiles.find((f) => f.category === 'pitchDeck');
      if (!pDeck) {
        toast.error('Please upload pitch deck file', '');
        return;
      }
    }

    if (values && values['industries'] && values['industries']?.length > 3) {
      toast.error('You can only select up to 3 Industries', '');
      return;
    }
    if (!pictureUrl) {
      toast.error('Please upload profile picture', '');
      return;
    }
    setIsUpdating(true);
    values['logoUri'] = pictureUrl ? pictureUrl : null;
    values.teamMembers = teamMembers;
    values.companyFiles = companyFiles;
    values['socialLinks'] = socialLinks.length !== 0 ? [...socialLinks] : [];
    const updatedCompany = { ...company, ...values };

    if (pitchDeckImages.length) {
      updatedCompany.pitchDeckImageFiles = pitchDeckImages;
    }

    try {
      await updateCompany(updatedCompany);
      // navigateTo('/company/' + company.id);
      navigateTo(-1);
      toast.success(`Your profile is updated`, '');
    } catch (error) {
      console.warn(error);
      toast.warning('Failed to save', '');
    } finally {
      setTimeout(() => {
        setIsUpdating(false);
      }, 600);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    toast.error('Failed to save', 'Please fill the required fields');
  };

  return (
    <div className="body_layout">
      <Form
        initialValues={company}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="sub_header">
          <div className="back_icon">
            <BackArrow onClick={() => navigateTo(-1)} />
            <h2>Back</h2>
          </div>

          <div className="sub_header_actions">
            <>
              {/* <FileUploader
                onUploadComplete={(e) => {
                  setPictureUrl(e?.url);
                }}
              ></FileUploader> */}
            </>

            {/* {pictureUrl ? (
              <Popover
                placement="bottom"
                content={
                  <div>
                    <Space direction="vertical">
                      <a
                        onClick={() => {
                          setPictureUrl(null);
                          // company["logoUri"] = undefined
                          // setCompany({ ...company, logoUri: null });
                          setOpenLogoMenu(false);
                        }}
                      >
                        Change
                      </a>
                      <a
                        onClick={() => {
                          setPictureUrl(null);
                          // company["logoUri"] = undefined
                          // setCompany({ ...company, logoUri: null });
                          setOpenLogoMenu(false);
                        }}
                      >
                        Remove
                      </a>
                    </Space>
                  </div>
                }
                open={openLogoMenu}
                onOpenChange={(newOpen: boolean) => {
                  setOpenLogoMenu(newOpen);
                }}
              >
                <Avatar
                  shape="square"
                  alt="Logo"
                  icon={
                    <img
                      src={pictureUrl ? pictureUrl : DEFAULT_FIRM_IMG}
                      alt=""
                    />
                  }
                />
              </Popover>
            ) : ( */}

            <Avatar
              shape="square"
              alt="Logo"
              icon={
                <img src={pictureUrl ? pictureUrl : DEFAULT_FIRM_IMG} alt="" />
              }
            />

            <div className="upload-container">
              <label className="upload-box">
                <FileUploader
                  onUploadComplete={(e) => {
                    setPictureUrl(e?.url);
                  }}
                ></FileUploader>
              </label>
            </div>

            {/* )} */}
          </div>
        </div>

        <div className="wvp-p-flex-d">
          <h3 className="Secondary-textcolor">Company</h3>

          <div className="grid-3 ">
            <Form.Item
              name="name"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter company name',
                },
              ]}
            >
              <Input size="large" placeholder="company name" />
            </Form.Item>
            <Form.Item
              name="businessModal"
              label="Business Model"
              rules={[
                {
                  required: true,
                  message: 'Please select Business Model',
                },
              ]}
            >
              <Select mode="multiple" options={business_model?.options ?? []} />
            </Form.Item>

            <Form.Item
              name="isFounderHasHadAnExit"
              label="Founder has had an exit"
              rules={[
                {
                  required: true,
                  message: 'Please select Buisness Modal',
                },
              ]}
            >
              <Select options={YES_NO_DDL_LIST} />
            </Form.Item>

            <Form.Item
              name="diversityDesignation"
              label="Diversity Designation"
              rules={[
                {
                  required: false,
                  message: 'Please select Buisness Modal',
                },
              ]}
            >
              <Select options={diversity_designation?.options ?? []} />
            </Form.Item>

            <Form.Item
              name="annualRecurringRevenue"
              label="Annual Revenue"
              rules={[
                {
                  required: true,
                  message: 'Please select Annual Revenue',
                },
              ]}
            >
              <Select options={arr_range?.options ?? []} />
            </Form.Item>

            <Form.Item
              name="acceleratorProgramGraduate"
              label="Accelerator Program Graduate "
              rules={[
                {
                  required: true,
                  message: 'Please select Buisness Modal',
                },
              ]}
            >
              <Select options={YES_NO_DDL_LIST} />
            </Form.Item>

            <Form.Item
              name="minorityDesignation"
              label=" Minority Designation"
              rules={[
                {
                  required: false,
                  message: 'Please select Buisness Modal',
                },
              ]}
            >
              <Select options={minority_designation?.options ?? []} />
            </Form.Item>

            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: 'Please enter valid city',
                },
              ]}
            >
              <Input placeholder="Enter City" name="city" />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: 'Please enter valid state',
                },
              ]}
            >
              <Input placeholder="Enter State" />
            </Form.Item>

            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: 'Please enter country',
                },
              ]}
            >
              <Input placeholder="Enter country" />
            </Form.Item>

            <Form.Item
              name="websiteUri"
              label="Website"
              rules={[
                {
                  required: true,

                  message: 'Please enter valid website',
                },
                { type: 'url' },
                { type: 'string', min: 6 },
              ]}
            >
              <Input placeholder="Enter website" />
              {/* <Input placeholder="Enter website" /> */}
            </Form.Item>

            <Form.Item
              name="employeeCount"
              label="Number of Employees"
              rules={[
                {
                  required: true,
                  message: 'Please enter Number of employees',
                },
              ]}
            >
              <InputNumber type="number" placeholder="Number of employees" />
            </Form.Item>
          </div>
          <h3 className="Secondary-textcolor">Funding</h3>

          <div className="grid-3">
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: 'Please select status',
                },
              ]}
            >
              <Select
                onChange={(e: any) => {
                  debugger;
                  //  setStatusValue(e);
                }}
                options={[
                  { value: true, label: 'Raising' },
                  { value: false, label: 'Not-Raising' },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="fundingRound"
              label="Round"
              rules={[
                {
                  required: true,
                  message: 'Please select Round',
                },
              ]}
            >
              <Select options={funding_round?.options ?? []} />
            </Form.Item>

            <Form.Item
              name="closingDate"
              label="Round Close Date"
              getValueFromEvent={(onChange) =>
                onChange ? dayjs(onChange).toDate() : ''
              }
              getValueProps={(value) => ({
                value: value ? dayjs(value) : '',
              })}
            >
              <DatePicker format={'MMM D, YYYY'} />
            </Form.Item>

            <Form.Item
              name="seekingAmount"
              label="Seeking"
              rules={[{ required: true, message: 'Please enter Seeking' }]}
            >
              <InputNumber
                prefix="$"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="structures"
              label="Structure"
              rules={[
                {
                  required: true,
                  message: 'Please select Structure',
                },
              ]}
            >
              <Select
                mode="multiple"
                options={company_structure?.options ?? []}
              />
            </Form.Item>

            <Form.Item
              name="minimumInvestment"
              label="Minimum Investment"
              rules={[
                {
                  required: true,
                  message: 'Please enter Minimum Investment',
                },
              ]}
            >
              <InputNumber
                prefix="$"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                placeholder="Minimum Investment"
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              name="preFundingValuation"
              label="Pre-funding Valuation"
              rules={[
                {
                  required: true,
                  message: 'Please enter Prefunding Valuation',
                },
              ]}
            >
              <Select options={pre_funding_valuation.options ?? []} />
            </Form.Item>

            <Form.Item
              name="allowSyndicateDeals"
              label="Syndicate Deals"
              rules={[
                {
                  required: true,
                  message: 'Please enter Syndicate Deals',
                },
              ]}
            >
              <Select
                options={[
                  { value: false, label: 'Not allowed' },
                  { value: true, label: 'Allowed' },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="fundingRaisedToDate"
              label="Funding Raised to Date"
              rules={[
                {
                  required: true,
                  message: 'Please enter Raised to-date',
                },
              ]}
            >
              <InputNumber
                prefix="$"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>
          <h3 className="Secondary-textcolor">About</h3>
          <Form.Item
            name="about"
            rules={[{ required: true, message: 'Please enter Intro' }]}
          >
            {/* <Input.TextArea rows={10} /> */}
            <ReactQuill theme="snow" />
          </Form.Item>
          <h3 className="Secondary-textcolor">Industries</h3>
          <Form.Item
            name="industries"
            rules={[
              {
                required: true,
                message: 'Please select Industries',
              },
            ]}
          >
            <Select
              mode="multiple"
              // size={size}
              // style={{width:'250px'}}
              placeholder="Please select"
              defaultValue={industries?.length ? [...industries] : []}
              onChange={(e) => setIndustries(e)}
              options={industry_type?.options ?? []}
            />
          </Form.Item>
          {industries.length >= 4 && (
            <p style={{ color: 'red' }}>You can only select up to 3 options</p>
          )}

          <h3 className="Secondary-textcolor">Team</h3>
          <TeamMemberForm
            onAddMember={(teamMember) => {
              setTeamMembers([...teamMembers, teamMember]);
            }}
          ></TeamMemberForm>

          <div>
            <TeamMembersList
              teamMembers={teamMembers}
              onDeleteMember={(index) => {
                teamMembers.splice(index, 1);
                setTeamMembers?.([...teamMembers]);
              }}
            ></TeamMembersList>
          </div>

          <h3 className="Secondary-textcolor">
            Social <span>Links</span>{' '}
          </h3>
          <SocialLinksEditor
            value={socialLinks}
            onChange={(v) => {
              debugger;
              setSocialLinks(v);
            }}
          />

          <Form.Item>
            <CompanyFilesTabs
              value={companyFiles}
              profile={profile}
              onChange={(v) => setCompanyFiles(v)}
              onPitchDeckImagesUpdate={(v) => setPitchDeckImages(v)}
            />
          </Form.Item>

          <div className="actions_btn">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="bg-primary"
                loading={isUpdating}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}

// TODO: strictly type internal methods
const CompanyFilesTabs: React.FC<{
  value?: CompanyFile[];
  profile: Profile;
  // onSaveLink:(value: string) => void;
  onChange?: (value: CompanyFile[]) => void;
  onPitchDeckImagesUpdate: (value: string[]) => void;
}> = ({
  value: companyFiles,
  profile,
  onChange: setCompanyFiles,
  onPitchDeckImagesUpdate: setPitchDeckImages,
}) => {
  const [isViewerOpened, setIsViewerOpened] = useState(false);

  // const [link, setLink] = useState<any>();
  // to check and remove
  // type TabPosition = 'left' | 'right' | 'top' | 'bottom';
  // const [tabPosition] = useState<TabPosition>('bottom');

  //const storage = getStorage();
  // const [companyFiles, setCompanyFiles] = useState<CompanyFile[]>([]);

  // to check and remove
  // const getFilePath = (fileUrl) => {
  // TO DO -- Clean this logic
  //   fileUrl = decodeURIComponent(fileUrl);
  //   return fileUrl.split('?')[0].split('/').slice(-2).join('/');
  // };

  const loadPreview = (fileUrl: string, type: string): void => {
    // getDownloadURL(ref(storage, getFilePath(fileUrl))).then((liveUrl) => {
    //   setViewFile({
    //     zoompercentage: 100,
    //     fileUrl: liveUrl,
    //     type: type,
    //   });
    //   setIsViewerOpened(true);
    // });
  };
  const [viewFile] = useState<{
    zoompercentage: number;
    fileUrl: string;
    type: string;
  } | null>(null);

  const getFileInfoByURL = (url: string) => {
    url = decodeURIComponent(url);
    const filePath = url.split('?')[0];
    return {
      filePath,
      fileExt: filePath.split('.').pop(),
      fileName: filePath.split('/').pop()?.split('--').pop(),
    };
  };

  const getFileCard = (file: CompanyFile) => {
    const url = decodeURIComponent(file.url!); // TODO: should be required in the SDK
    const filePath = url.split('?')[0],
      fileExt = filePath.split('.').pop(),
      fileName = filePath.split('/').pop()?.split('--').pop();

    const iconStyels = defaultStyles[fileExt] ? defaultStyles[fileExt] : {};
    return (
      <div className="doc_files">
        <div
          className=""
          onClick={() => {
            if (fileExt) loadPreview(file.url!, fileExt);
          }}
        >
          <div className="file_icon">
            <FileIcon extension={fileExt} {...iconStyels} />
          </div>
        </div>
        <div
          className="load_preview"
          onClick={() => {
            if (fileExt) loadPreview(file.url!, fileExt);
          }}
        >
          <div className="file_name">{fileName}</div>
          <div>{formatBytes(file.size)}</div>
        </div>
        <div className="delete_icon">
          <DeleteIcons
            style={{ color: 'red' }}
            onClick={(e) => {
              e.preventDefault();
              const newFiles = companyFiles?.filter(
                (cf) => cf.url !== file.url,
              );
              // Remove File From DB...!
              setCompanyFiles?.([...(newFiles ?? [])]);
            }}
          />
        </div>
      </div>
    );
  };
  const addFileToCompany = async (
    fileCategory: string,
    file: { url: string; size?: number; images?: string[] },
  ): Promise<void> => {
    let oFiles = companyFiles && companyFiles?.length ? [...companyFiles] : [];

    if (fileCategory === CompanyFileCategories.intro)
      oFiles = oFiles?.filter(
        (f) => f.category !== CompanyFileCategories.intro,
      );

    if (fileCategory === CompanyFileCategories.pitchDeck) {
      setPitchDeckImages?.(file.images ?? []);
    }

    setCompanyFiles?.([...oFiles, { category: fileCategory, ...file }]);
  };

  const removeIntroVideo = (): void => {
    setCompanyFiles?.([
      ...(companyFiles ?? [])?.filter(
        (f) => f.category !== CompanyFileCategories.intro,
      ),
    ]);
  };

  // const exexutiveSummaryView = () => {
  //   let esFiles = companyFiles?.filter(
  //     (f) => f.category === CompanyFileCategories.executiveSummary,
  //   );
  //   return (
  //     <div className=" videoWrapper  content-center">
  //       <div className={`${!esFiles?.length ? 'no-content' : 'content'}`}>
  //         <FileUploader
  //           acceptFileTypes="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
  //           label="Add Executive Summary"
  //           onUploadComplete={(file) => {
  //             debugger;
  //             if (file) {
  //               addFileToCompany(CompanyFileCategories.executiveSummary, file);
  //             }
  //           }}
  //         ></FileUploader>
  //       </div>
  //       <div className="edit-docs-view">
  //         {esFiles?.map((file) => {
  //           return <>{getFileCard(file)}</>;
  //         })}
  //       </div>
  //     </div>
  //   );
  // };

  const pitchdeckView = () => {
    const pdFiles = companyFiles?.filter(
      (f) => f.category === CompanyFileCategories.pitchDeck,
    );

    return (
      <>
        <div className=" videoWrapper  content-center  ">
          {!pdFiles?.length ? (
            <div className={`${!pdFiles?.length ? 'no-content' : 'content'}`}>
              <PdfToImagesUploader
                orgId={profile.organisationId}
                onUploadComplete={({ pdfFile, images, size }) => {
                  addFileToCompany(CompanyFileCategories.pitchDeck, {
                    url: pdfFile,
                    images,
                    size,
                  });
                }}
              ></PdfToImagesUploader>
              {/* <div className="upload-container">
                <label className="upload-box">
                  <div className="upload-icon">
                    {' '}
                    <UploadCloudIcon />
                  </div>
                  <div>
                    <p>Select a file</p>
                    <p className="file-size-info">
                      PDF file size no more than 10MB
                    </p>
                  </div>
                  
                  <FileUploader
                    // acceptFileTypes="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, .pptx"
                    acceptFileTypes="application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/pdf,image/*,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                    label="Add Pitch Deck"
                    onUploadComplete={(file) => {
                      if (file) {
                        addFileToCompany(CompanyFileCategories.pitchDeck, file);
                      }
                    }}
                  ></FileUploader>
                </label>
              </div> */}
            </div>
          ) : null}

          <div className="edit-docs-view">
            {pdFiles?.map((file) => {
              return (
                <div className="w-100" key={'file'}>
                  {getFileCard(file)}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const IntroView = () => {
    let introFile, fileInfo;
    if (companyFiles && companyFiles.length !== 0) {
      introFile = companyFiles.find(
        (f) => f.category === CompanyFileCategories.intro,
      );
      fileInfo = introFile && getFileInfoByURL(introFile.url);
    }

    if (!introFile)
      return (
        <div className="flex_warp">
          <div className={`${!introFile ? 'no-content' : 'content'}`}>
            <div className="upload-container">
              <label className="upload-box">
                <div className="upload-icon">
                  {' '}
                  <UploadCloudIcon />
                </div>
                <div>
                  <p>Add Company Video</p>
                  <p className="file-size-info">Mp4, M4v file</p>
                </div>
                <FileUploader
                  acceptFileTypes="video/mp4,video/x-m4v,video/*"
                  label="Add Intro Video"
                  onUploadComplete={(file) => {
                    if (file)
                      addFileToCompany(CompanyFileCategories.intro, file);
                  }}
                ></FileUploader>
              </label>
            </div>
          </div>

          <span className="or_center">Or</span>

          <Input
            className="video_link"
            size="large"
            onBlur={(e) => {
              // setLink(e.target.value);
              addFileToCompany(CompanyFileCategories.intro, {
                url: e.target.value,
                size: 5584,
              });
            }}
            placeholder="Place youtube / vimeo link here"
          />
        </div>
      );

    return (
      <div className=" videoWrapper  content-center">
        <div className="video_file">
          <h5 className="video-title-txt" title={fileInfo?.fileName}>
            {fileInfo?.fileName}
          </h5>
          <div className="delete_icon">
            <DeleteIcons onClick={() => removeIntroVideo()} />
          </div>
        </div>
        <VideoByUrl link={introFile.url}></VideoByUrl>
      </div>
    );
  };

  return (
    <div className="deck-slideshow">
      <Tabs
        className="tabs-bottom"
        tabPosition={'top'}
        items={[
          {
            label: 'Pitch Deck',
            key: 'pdeck-345',
            children: pitchdeckView(),
          },
          {
            label: 'Overview',
            key: 'intvdo-323',
            children: IntroView(),
          },
          // {
          //   label: 'Link',
          //   key: 'Link',
          //   children: linkView(),
          // },
        ]}
      />
      <Modal
        title="File Preview"
        centered
        open={isViewerOpened}
        onOk={() => setIsViewerOpened(false)}
        onCancel={() => setIsViewerOpened(false)}
        width={1000}
      >
        {!viewFile?.fileUrl || isViewerOpened ? (
          <></>
        ) : (
          <FileLoader
            zoompercentage={viewFile?.zoompercentage}
            fileUrl={viewFile?.fileUrl}
            type={viewFile?.type}
          ></FileLoader>
        )}
      </Modal>
    </div>
  );
};
