import { VideoPlayer } from '../common/VideoPlayer';

export interface IVideoByUrlProps {
  link: string;
}

export default function VideoByUrl(props: IVideoByUrlProps) {
  const YouTubePreview = ({ url }) => {
    function getVideoEmbedUrl(url) {
      let embedUrl;
      // YouTube URL patterns
      const youtubeRegex =
        /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i;
      // Vimeo URL patterns
      const vimeoRegex =
        /(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\w+\/)?|album\/(?:\w+\/)?|video\/|)(\d+)(?:$|\/|\?))/;

      if (youtubeRegex.test(url)) {
        const videoId = url.match(youtubeRegex)[1];
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      } else if (vimeoRegex.test(url)) {
        const videoId = url.match(vimeoRegex)[1];
        embedUrl = `https://player.vimeo.com/video/${videoId}`;
      } else {
        console.error('Invalid video URL');
      }

      return embedUrl;
    }

    const videoLink = getVideoEmbedUrl(url);

    // if (!videoLink) {
    //   return <div>Invalid YouTube URL</div>;
    // }

    return (
      <div
        style={{
          position: 'relative',
          paddingBottom: '56.25%',
          height: 0,
          overflow: 'hidden',
        }}
      >
        {videoLink ? (
          <iframe
            title="Video"
            src={videoLink}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
            }}
            allowFullScreen
          />
        ) : (
          <VideoPlayer url={url}></VideoPlayer>
        )}
      </div>
    );
  };

  return (
    <div>
      {' '}
      <YouTubePreview url={props.link} />
    </div>
  );
}
