import { useEffect } from 'react';
import useProfileStore from '../../data/appState/profileStore';
import React from 'react';
import { InvestmentFirm } from '../../domain/types/Investor';
import { getInvestmentFirmById } from '../../data/services/investor/investorService';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpandedInvestmentFirmView } from '../components/ExpandedInvestmentFirmView';
import { BackArrow } from '../common/utilComponents';

export interface IInvestorFirmMessageViewProps {}

export default function InvestorFirmMessageView(
  props: IInvestorFirmMessageViewProps,
) {
  const { id: investorFirmId } = useParams();

  const { profile } = useProfileStore();
  const orgId = investorFirmId || profile?.organisationId;
  const navigateTo = useNavigate();

  const [investmentFirm, setInvestmentFirm] =
    React.useState<InvestmentFirm | null>(null);

  useEffect(() => {
    if (orgId)
      getInvestmentFirmById(orgId)
        .then((data) => {
          setInvestmentFirm(data);
        })
        .catch((err) => {});
  }, [orgId]);

  return (
    <div className="body_layout">
      {/* <Helmet>
      <title>{`WealthVP | ${investor?.legalName ?? investor?.name}`}</title>
      <link
        rel="canonical"
        href="https://wealthvp.com/company/{company?.id}"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="{'WealthVP | ' + investor?.legalName}"
      />
      <meta
        property="og:image"
        content="https://wealthvp.com/assets/favicon.png"
      />
      <meta
        property="og:description"
        content="{'Company profile of ' + investor?.legalName}"
      />
      <meta
        property="og:url"
        content="https://wealthvp.com/company/{company?.id}"
      />
      <meta property="og:site_name" content="WealthVP" />
    </Helmet> */}

      <div className="sub_header">
        <div className="back_icon" onClick={() => navigateTo(-1)}>
          <BackArrow />
          <h1>Back</h1>
        </div>
      </div>

      <div className="wvp-p-flex-d">
        <div className="left-top-position">
          <div className="left-info"></div>

          <div className="deck-slideshow">
            <div className="re-company-deck">
              {investmentFirm ? (
                <ExpandedInvestmentFirmView
                  investmentFirm={investmentFirm}
                  showSocialIcons={true}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
