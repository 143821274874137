import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Invite } from '../../domain/types/Invite';
import {
  getCurrentUser,
  signInWithEmailPassword,
  signOutUser,
  signUpWithEmailAndPassword,
} from '../../data/services/auth/authService';
import { useEffect, useState } from 'react';
import {
  getInviteById,
  acceptInvite,
} from '../../data/services/Invites/InviteService';
import { LoginFooter, LoginHeader } from './Login';
import { Button, Divider, Form, Input, Select } from 'antd';
import {
  investorSiteProperties,
  useLoadSiteData,
} from '../../data/services/common/siteDataService';
import { InvestmentFirm } from '../../domain/types/Investor';
import {
  createInvestmentFirm,
  updateInvestorFirm,
} from '../../data/services/investor/investorService';
import { Profile } from '../../domain/types/Profile';
import { createProfile } from '../../data/services/profile/profileService';
import { ProcessState } from '../common/types';
import toast from '../common/Toast';

export interface IInviteOnboardProps {}

export function InviteOnboard(props: IInviteOnboardProps) {
  const { loaded } = useLoadSiteData();
  const [processState, setProcessState] = useState<ProcessState>(
    ProcessState.Idle,
  );

  const { inviteid } = useParams();
  const [form] = Form.useForm<any>();
  const { investor_type } = investorSiteProperties ?? {};
  const [isUserLoggedVerified, setIsUserLoggedVerified] =
    React.useState<boolean>(false);

  const [invite, setInvite] = React.useState<Invite | null>(null);

  useEffect(() => {
    const User = getCurrentUser();
    if (User?.uid) {
      signOutUser().then(() => {
        setIsUserLoggedVerified(true);
      });
    } else {
      setIsUserLoggedVerified(true);
    }
  }, []);

  useEffect(() => {
    if (isUserLoggedVerified && inviteid) {
      getInviteById(inviteid).then((invite: Invite) => {
        if (invite) {
          setInvite(invite);
        }
      });
    }
  }, [isUserLoggedVerified, inviteid]);

  if (!loaded) {
    return (
      <div className="loader_center">
        <h1>Loading...</h1>
      </div>
    );
  }

  if (!isUserLoggedVerified) {
    return (
      <div className="loader_center">
        <h1>Logging out...</h1>
      </div>
    );
  }

  if (!invite) {
    return (
      <div className="loader_center">
        <h1>Invite not found</h1>
      </div>
    );
  }

  const handleSignUp = async (values: any) => {
    setProcessState(ProcessState.Loading);
    try {
      if (invite.createToOrgType === 'INVESTOR') {
        const investorOrg = {} as InvestmentFirm;
        investorOrg.investorType = values.investorType;
        investorOrg.name = values.firmName;
        investorOrg.websiteUri = values.firmWebSite;
        investorOrg.linkedinUri = values.linkedinUrl;
        investorOrg.updatedOn = new Date();
        investorOrg.createdOn = new Date();
        investorOrg.isActive = true;
        investorOrg.isHidden = false;
        investorOrg.teamMembers = [];

        const res = await signUpWithEmailAndPassword(
          values.email,
          values.password,
        );

        if (res?.user?.uid) {
          const firmRes = await createInvestmentFirm(investorOrg);

          if (firmRes?.id) {
            const profile = {} as Profile;

            profile.firstName = values.firstName;
            profile.lastName = values.lastName;
            profile.email = values.email;
            profile.organisationName = values.firmName;
            profile.displayName = values.firstName + ' ' + values.lastName;
            profile.organisationId = firmRes.id;
            profile.orgType = 'INVESTOR';
            profile.uid = res?.user?.uid;
            profile.updatedOn = new Date();
            profile.isActive = true;
            profile.userStatus = 'activated'; // payment
            profile.isWalkThroughPending = true;

            // Update the profile
            const profileRes = await createProfile(profile);

            if (profileRes?.id && inviteid) {
              await acceptInvite(inviteid, {
                status: 'accepted',
                updatedOn: new Date(),
                acceptedOn: new Date(),
                acceptedById: res?.user?.uid,
                acceptedByEmail: values.email,
                acceptedByName: values.firstName + ' ' + values.lastName,
                acceptedByOrgId: firmRes.id,
                acceptedByOrgName: values.firmName,
              } as Invite);

              await updateInvestorFirm({
                ...investorOrg,
                id: firmRes.id,
                createdById: profileRes?.id,
              });
            } else {
              console.error('Profile Not Created');
            }

            toast.success('Sign Up', 'You have been signed up successfully');

            await signInWithEmailPassword({
              email: values.email,
              password: values.password,
            });

            setTimeout(() => {
              window.location.href = `/investor/${firmRes.id}/edit`;
            }, 1000);
          } else {
            console.error('Investor Firm Not Created');
          }
        } else {
          console.error('Sign Up Failed');
        }
      } else {
        // Other Org Types
        console.error('Org Type Not Supported');
      }
    } catch (error) {
      console.error('Error', error);
      toast.error('Error', 'An error occured while signing up');
    } finally {
      setProcessState(ProcessState.Idle);
    }
  };

  return (
    <div className="login_page invitee_signup">
      <LoginHeader title={'Sign Up'} />

      <div className="invitee_text">
        <h5>
          You have been invited by{' '}
          <b className="highlight_color">{invite.createdByName}</b> from
          <b className="highlight_color"> {invite.createdByOrgName}</b>
        </h5>
      </div>

      <div className="invitee_form">
        <Form
          layout={'vertical'}
          initialValues={{ email: invite.createToEmail }}
          form={form}
          onFinish={handleSignUp}
        >
          <div className="flex_box">
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'Please Provide First Name',
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Last Name',
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>
          <div className="flex_box">
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Email',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Password',
                },
              ]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>
          </div>

          <Divider
            orientation="left"
            plain
            style={{ color: 'white', borderColor: '#ccc' }}
          ></Divider>
          <div>
            <h3 className="soft_green">Firm Information</h3>
          </div>

          <div className="flex_box">
            <Form.Item
              name="firmName"
              label="Firm Name"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Firm Name',
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              name="investorType"
              label="Investor Type"
              rules={[
                {
                  required: true,
                  message: 'Please select investor type',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                options={investor_type?.options ?? []}
              />
            </Form.Item>
          </div>

          <div className="flex_box">
            <Form.Item
              name="firmWebSite"
              label="Firm WebSite"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Firm WebSite',
                },
              ]}
            >
              <Input placeholder="Firm WebSite" />
            </Form.Item>

            <Form.Item
              name="linkedinUrl"
              label="Linkedin Url"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Linkedin Url',
                },
              ]}
            >
              <Input placeholder="Linkedin Url" />
            </Form.Item>
          </div>

          <div className="flex_button">
            <Button
              type="primary"
              htmlType="submit"
              loading={processState === ProcessState.Loading}
            >
              {' '}
              Sign Up
            </Button>
          </div>
        </Form>
      </div>

      <div></div>
      <LoginFooter />
    </div>
  );
}
