import { Button, Form, Input } from 'antd';
import * as React from 'react';
import { TeamMember } from '../../domain/types/Company';
import FileUploader from '../common/FileUploader';
import { useState } from 'react';
import toast from '../common/Toast';
import { UploadCloudIcon } from '../common/utilComponents';

export interface ITeamMemberFormProps {
  onAddMember: (TeamMember) => void;
}

export function TeamMemberForm(props: ITeamMemberFormProps) {
  const [teamMember, setTeamMember] = useState<TeamMember>({});
  const [reseting, setReseting] = useState<boolean>(false);

  const updateField = (key: string, value: string) =>
    setTeamMember({ ...teamMember, [key]: value });

  const handleAddClick = () => {
    if (!teamMember.displayName) {
      toast.warning('Please enter Name');
      return;
    }
    if (!teamMember.title) {
      toast.warning('Please enter Title');
      return;
    }
    if (!teamMember.photoUrl) {
      toast.warning('Please Upload a Photo');
      return;
    }
    const tm = { ...teamMember };

    props.onAddMember({ ...tm });
    setTeamMember({});
    reset();
  };

  const reset = () => {
    setReseting(true);
    setTimeout(() => {
      setReseting(false);
    }, 500);
  };

  if (reseting) return <div className="empty-form"></div>;

  return (
    <div className="team_upload">
      <Form requiredMark={false} className="grid-2" layout="vertical">
        <Form.Item
          label="Name"
          name="displayName"
          rules={[{ required: true, message: 'Please enter Name' }]}
        >
          <Input
            placeholder="Name"
            id="tb-dsname"
            onChange={(e) => updateField('displayName', e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="Social Link"
          name="socialLink"
          rules={[{ required: true, message: 'Please enter social Link' }]}
        >
          <Input
            placeholder="Social Link"
            id="tb-dsname"
            onChange={(e) => updateField('socialLink', e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: 'Please enter Title',
            },
          ]}
        >
          <Input
            placeholder="Chief Executive Officer (CEO)"
            onChange={(e) => updateField('title', e.target.value)}
          />
        </Form.Item>

        <div className="upload-container">
          <label className="upload-box">
            <div className="upload-icon">
              {' '}
              <UploadCloudIcon />
            </div>
            <div>
              <p>Select a file</p>
              <p className="file-size-info">
                Image file size no more than 10MB
              </p>
            </div>
            <FileUploader
              acceptFileTypes="image/*"
              onUploadComplete={(file) => {
                if (file?.url) {
                  updateField('photoUrl', file?.url);
                }
              }}
            />
          </label>
        </div>

        <Form.Item>
          <Button
            style={{ marginTop: 'auto' }}
            type="default"
            className="bg-primary"
            onClick={handleAddClick}
          >
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
