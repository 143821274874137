//'REQUESTED' | 'ACCEPTED' | 'BLOCKED'
export enum ConnectionStatus {
  NOT_CONNECTED = 'NOT_CONNECTED',
  PENDING = 'PENDING',
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  BLOCKED = 'BLOCKED',
  CANCELLED = 'CANCELLED',
}

export enum ConnectionType {
  ORG_ORG = 'ORG_ORG',
  ORG_USER = 'ORG_USER',
  USER_USER = 'USER_USER',
}

export enum ConversationType {
  GROUP = 'GROUP',
  DIRECT = 'DIRECT',
}
