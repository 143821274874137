import { useEffect, useState } from 'react';
import useProfileStore from '../../data/appState/profileStore';
import { getAllOpportunitiesByFirmId } from '../../data/services/realEstate/realEstateOpportunityService';
import { Space, Button } from 'antd';
import { REAL_ESTATE_IMG } from '../common/constants';
import { useNavigate } from 'react-router-dom';
import { RealEstateOpportunity } from '../../domain/types/RealEstateOpportunity';
import { realestateSiteProperties } from '../../data/services/common/siteDataService';
import { getlabelsByValues } from '../../data/services/explore/converters';
import { BackArrow } from '../common/utilComponents';

export interface IRealEstateOpportunitiesProps {}

export default function RealEstateOpportunities(
  props: IRealEstateOpportunitiesProps,
) {
  window.scrollTo(0, 0);

  const navigateTo = useNavigate();

  const { profile } = useProfileStore();
  const [opportunities, setOpportunities] = useState<RealEstateOpportunity[]>();

  const {
    property_sector,
    investment_strategy,
    investment_structure,
    return_structure,
    tax_advantage,
    anticipated_term,
    investment_type,
  } = realestateSiteProperties ?? {};
  useEffect(() => {
    if (profile && profile.organisationId) {
      getAllOpportunitiesByFirmId(profile.organisationId).then((data) => {
        setOpportunities(data);
      });
    }
  }, [profile]);

  return (
    <div className="body_layout">
      <div className="sub_header">
        <div className="back_icon">
          <BackArrow onClick={() => navigateTo(-1)} />
          <h3>Back</h3>
        </div>
        <div className="sub_header_actions">
          <li onClick={() => navigateTo('/createopportunity')}>
            <Button type="primary">Create Listing</Button>
          </li>
        </div>
      </div>

      <div className="layout-grid">
        {opportunities?.map((oppo: any) => {
          return (
            <div key={oppo?.id}>
              <div className="main-card" key={oppo.id}>
                <div className="card-sub-header scroll-h-img">
                  {oppo &&
                  oppo?.pictureUrls &&
                  oppo.pictureUrls.length !== 0 &&
                  oppo.pictureUrls[0] ? (
                    <>
                      <div
                        className="container-img div-img"
                        key={oppo.pictureUrls[0]}
                      >
                        <img
                          className="company-logo"
                          src={oppo.pictureUrls[0]}
                          alt="oppo logo"
                        />
                      </div>
                      {/* {oppo?.pictureUrls?.map((pic) => {
                        return (
                          <>
                            <div className="container-img div-img" key={pic}>
                              <img className="company-logo" src={pic} />
                            </div>
                          </>
                        );
                      })} */}
                    </>
                  ) : (
                    <>
                      <div className="container-img div-img">
                        <img
                          className="company-logo"
                          src={REAL_ESTATE_IMG}
                          alt="oppo logo"
                        />
                      </div>{' '}
                    </>
                  )}
                </div>
                <div className="card-content">
                  <h2>{oppo?.name}</h2>
                  <div className="card-info">
                    <div className="entity-details">
                      <div className="list-info">
                        <span className="list_label">Sectors</span>
                        <span
                          className="list_value"
                          title={oppo?.sectors.toString()}
                        >
                          {getlabelsByValues(
                            oppo?.sectors,
                            property_sector?.options,
                          ).map((label: any, index: number) => (
                            <span className="sub-value" key={index}>
                              {label}
                            </span>
                          ))}
                        </span>
                      </div>
                      <div className="list-info">
                        <span className="list_label">Investment Strategy</span>
                        <span className="list_value">
                          {getlabelsByValues(
                            oppo?.investmentStrategy,
                            investment_strategy?.options,
                          ).map((label: any, index: number) => (
                            <span className="sub-value" key={index}>
                              {label}
                            </span>
                          ))}
                        </span>
                      </div>
                      <div className="list-info">
                        <span className="list_label">Investment Structure</span>
                        <span className="list_value">
                          {getlabelsByValues(
                            oppo?.investmentStructure,
                            investment_structure?.options,
                          ).map((label: any, index: number) => (
                            <span className="sub-value" key={index}>
                              {label}
                            </span>
                          ))}
                        </span>
                      </div>
                      <div className="list-info">
                        <span className="list_label">Return Structure</span>
                        <span className="list_value">
                          {getlabelsByValues(
                            oppo?.returnStructure,
                            return_structure?.options,
                          ).map((label: any, index: number) => (
                            <span className="sub-value" key={index}>
                              {label}
                            </span>
                          ))}
                        </span>
                      </div>
                      <div className="list-info">
                        <span className="list_label">Anticipated Term</span>
                        <span className="list_value">
                          {getlabelsByValues(
                            [oppo?.anticipatedTerm],
                            anticipated_term?.options,
                          ).map((label: any, index: number) => (
                            <span className="sub-value" key={index}>
                              {label}
                            </span>
                          ))}
                        </span>
                      </div>
                      <div className="list-info">
                        <span className="list_label">Tax Advantage</span>
                        <span className="list_value">
                          {getlabelsByValues(
                            [oppo?.taxAdvantage],
                            tax_advantage?.options,
                          ).map((label: any, index: number) => (
                            <span className="sub-value" key={index}>
                              {label}
                            </span>
                          ))}
                        </span>
                      </div>
                      <div className="list-info">
                        <span className="list_label">Investment Type</span>
                        <span className="list_value">
                          {getlabelsByValues(
                            [oppo?.investmentType],
                            investment_type?.options,
                          ).map((label: any, index: number) => (
                            <span className="sub-value" key={index}>
                              {label}
                            </span>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Divider> </Divider> */}
                <Space
                  wrap
                  align="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '0.75rem',
                  }}
                  size={16}
                >
                  {/* <Button
                    type="default"
                    onClick={(e) => navigateTo(`/opportunity/${oppo.id}`)}
                  >
                    More
                  </Button> */}

                  <Button
                    type="primary"
                    onClick={(e) => {
                      navigateTo(`/updateopportunity/${oppo.db_ref_id}`);
                    }}
                  >
                    Edit
                  </Button>
                </Space>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
