import { ApiService } from '../apiService';

const analyticApi = new ApiService(
  'https://tools.wealthvp.com/api',
  '9b726329-386c-4cb1-af3b-3f969086fcaf',
);

export type TableConfig = {
  name: string;
  title: string;
  reportView?: string;
  reportViewConfig?: any;
};

export const tableNames = [
  'companyFundingRound',
  'companyIndusties',
  'companyInvestorRatio',
  'companyRevenue',
  'connectionStatus',
  'investorIndustries',
  'userNew',
];

export const tablesConfig: TableConfig[] = [
  {
    //[{"period":"2024-08","company_count":3,"investor_count":4},
    name: 'userNew',
    title: 'User New',
    reportView: 'chart',
    reportViewConfig: {
      chartType: 'line',
      xAxis: 'period',
      yAxis: ['company_count', 'investor_count'],
    },
  },
  {
    name: 'companyInvestorRatio',
    title: 'Company Investor Ratio',
    reportView: 'keyValue',
  },
  {
    name: 'connectionStatus',
    title: 'Connection Status',
    reportView: 'table',
    reportViewConfig: {
      columns: [
        {
          title: 'Status',
          dataIndex: 'status',
        },
        {
          title: 'Count',
          dataIndex: 'status_count',
        },
      ],
    },
  },
  {
    name: '',
    title: '',
  },

  {
    //{"industry":"AUTO_AND_TRANSPORTATION","industry_count":5},
    name: 'investorIndustries',
    title: 'Investor Industries',
    reportView: 'table',
    reportViewConfig: {
      columns: [
        {
          title: 'Industry',
          dataIndex: 'industry',
        },
        {
          title: 'Count',
          dataIndex: 'industry_count',
        },
      ],
    },
  },
  {
    name: 'companyIndusties',
    title: 'Company Industies',
    reportView: 'table',
    reportViewConfig: {
      columns: [
        {
          title: 'Industry',
          dataIndex: 'industry',
        },
        {
          title: 'Count',
          dataIndex: 'industry_count',
        },
      ],
    },
  },

  {
    name: 'companyFundingRound',
    title: 'Company Funding Round',
    reportView: 'chart',
    reportViewConfig: {
      chartType: 'bar',
      xAxis: 'fundingRound',
      yAxis: ['fundingRound_count'],
    },
  },

  {
    //[{"annualRecurringRevenue":null,"revenue_count":4},
    name: 'companyRevenue',
    title: 'Company Revenue',
    reportView: 'chart',
    reportViewConfig: {
      chartType: 'bar',
      xAxis: 'annualRecurringRevenue',
      yAxis: ['revenue_count'],
    },
  },
];

export const getConnectionsData = async () => {
  return analyticApi.get('connect/bigquery/test');
};

export const getConnectionsNewUsersData = async () => {
  return analyticApi.get('connect/bigquery/from-table?table=userNew');
};

export const getDataByTabelName = async (tableName: string) => {
  return analyticApi.get(`connect/bigquery/from-table?table=${tableName}`);
};
