import { useEffect, useState } from 'react';
import { ConfigProvider, Table } from 'antd';
import { getAllProfiles } from '../../../data/services/profile/profileService';
import { ColumnsType } from 'antd/es/table';
import { Profile } from '../../../domain/types/Profile';

export interface IUsersProps {}

export function Users(props: IUsersProps) {
  const [users, setUsers] = useState<any[]>([]);

  const columns: ColumnsType<Profile> = [
    {
      // title: `Username (${users?.length})`,
      title: 'Account Name',
      dataIndex: 'displayName',
      key: 'displayName',
      sorter: sortBy('displayName'),
    },

    {
      title: 'Account ID',
      dataIndex: 'organisationId',
      key: 'organisationId',
      sorter: sortBy('organisationId'),
    },

    {
      title: 'Org Name',
      dataIndex: 'organisationName',
      key: 'organisationName',
      sorter: sortBy('organisationName'),
    },

    {
      title: 'Account Type',
      dataIndex: 'orgType',
      key: 'orgType',
      sorter: sortBy('orgType'),
    },

    // {
    //   title: 'Role',
    //   dataIndex: 'role',
    //   key: 'role',
    // },
    // {
    //   title: 'Location',
    //   dataIndex: 'location',
    //   key: 'location',
    // },
    // {
    //   title: 'State',
    //   dataIndex: 'state',
    //   key: 'state',
    // },
    // {
    //   title: 'City',
    //   dataIndex: 'city',
    //   key: 'city',
    // },
    // {
    //   title: 'phone Number',
    //   dataIndex: 'phoneNumber',
    //   key: 'phoneNumber',
    // },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    //   key: 'email',
    // },
    {
      title: 'Account Status',
      dataIndex: 'userStatus',
      key: 'userStatus',
      sorter: sortBy('userStatus'),
    },
  ];

  const loadUsers = () => {
    getAllProfiles().then(
      (res: any) => {
        res = res.map((r: any) => {
          return {
            ...r,
            key: r.uid,
            location: `${r.country ? r.country + ',' : ''}  ${r.state ? r.state + ',' : ''} ${r.city ? r.city : ''}`,
          };
        });
        setUsers(res);
      },
      (e) => {},
    );
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <>
      <div className="users_view body_layout">
        <h1 className="header_title">Users</h1>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                rowHoverBg: '#0f1217',
              },
            },
          }}
        >
          <Table dataSource={users} columns={columns} key="table" />
        </ConfigProvider>
      </div>
    </>
  );
  function sortBy<T, P extends keyof T>(key: P) {
    return (a: T, b: T) => {
      const aa = a[key];
      const bb = b[key];

      if (typeof aa === 'string' && typeof bb === 'string') {
        return aa.localeCompare(bb);
      } else if (typeof aa === 'number' && typeof bb === 'number') {
        return aa - bb;
      }
      return 0;
    };
  }
}
